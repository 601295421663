import * as actionTypes from "./actionTypes";
import * as APIConstants from "../APIConstants";
import userApi from "../../userAPI";
import { filter, find, includes, map, reject } from "lodash";
import { apiStatusCode, PlayList } from "../../utils/constants";
export const getFitRadioList = (tenantId) => {
  return (dispatch, getState) => {
   dispatch({
     type: actionTypes.FIT_RADIO_LIST_START,
     payload: {
       error: false,
       msg: "",
       list: [],
       loading: true,
     },
   });
    const url = APIConstants.getFitRadioList;
    const body = { tenantId };
    userApi
      .post(url, body)
      .then((response) => {
        if (includes(apiStatusCode.success, response.status))
          dispatch({
            type: actionTypes.FIT_RADIO_LIST_SUCCESS,
            payload: {
              error: false,
              msg: PlayList.msg.get,
              list: response?.data ?? [],
            },
          });
        else throw new Error(response?.data);
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.FIT_RADIO_LIST_ERROR,
          payload: { error: true, msg: PlayList.msg.error },
        });
      })
      .finally(() => {
        dispatch({
          type: actionTypes.FIT_RADIO_LIST_END,
          payload: { loading: false,msg:"",error:false },
        });
      });
  };
};

export const getFitRadioMix = (listId, lastPlayedMixIds,tenantId) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.FIT_RADIO_MIX_START,
      payload: {
        error: false,
        msg: "",
        fitRadioMixData: [],
        loading: true,
      },
    });
    const url = APIConstants.getFitRadioMix;
    const body = { tenantId, listId, lastPlayedMixIds };
    userApi
      .post(url, body)
      .then((response) => {
        if (includes(apiStatusCode.success, response.status)){
         dispatch({
            type: actionTypes.FIT_RADIO_MIX_SUCCESS,
            payload: {
              error: false,
              msg: PlayList.msg.get,
              fitRadioMixData: { ...response.data, listId },
              lastPlayedMixIds:[...getState().fitRadio.lastPlayedMixIds,response?.data?.mixId],
            },
          });
        }
        else throw new Error(response?.data);
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.FIT_RADIO_MIX_ERROR,
          payload: { error: true, msg: PlayList.msg.error },
        });
      })
      .finally(() => {
        dispatch({
          type: actionTypes.FIT_RADIO_MIX_END,
          payload: { loading: false,msg:"",error:false},
        });
      });
  };
};

export const resetFitRadioData = () => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.FIT_RADIO_RESET_DATA,
      payload: {
        error: false,
        msg: "",
        fitRadioMixData: [],
        loading: false,
        list:[]
      },
    });
  }
}
