import React, {Fragment, useState, useEffect } from 'react';
import { Translate } from "utils/Translate";
import { Typography } from "@material-ui/core";
import Routes from 'components/Router/Routes';
import {appendQueryParameterInURL, getImage, getTranslatedFile } from 'utils/utilityfunctions';
import { useBrowseClasses } from "hooks/useBrowseClasses";
import IconRigntArrow from 'CommonImages/icon-rightArrow';
import { useNavigate } from 'react-router';
import { viewAllCategory } from 'utils/constants';

const config = require("assets/config.json");

const BrowseClasses = (props) => {
  const { useBrowseClassesState, useBrowseClassesDispatch } = useBrowseClasses();
  const { metadata,entitlement } = useBrowseClassesState()
  const { resetBrowseClassData,setClassesSearchData,setPaginatedData,resetFilters } = useBrowseClassesDispatch()
  const [categories, setCategories] = useState([])
  const [showArrow] = useState(props.showArrow)
  const navigate = useNavigate();


  useEffect(() => {
    setCategories(metadata?.categories)
  }, [metadata])

  const navigateToBrowseClasses=(category)=>{
    resetBrowseClassData()
    setClassesSearchData()  
    setPaginatedData()
    resetFilters()
    navigate(`${Routes.categories}${category?.id}`);
  }

  const viewAll =viewAllCategory

  const backGroundColor = {
    backgroundColor: "616161"
 };

  return (
    <Fragment>
      {categories && categories.length > 0 && (
        <div
          className={
            entitlement?.live
              ? "m-t-60 mobileScrollParent"
              : props.fromLandingPage !== true
              ? "m-t-30 mobileScrollParent"
              : "m-t-60 mobileScrollParent"
          }
        >
          {props.fromLandingPage !== true && (
            <div className="m-b-32 m-b-15">
              <div
                className={
                  showArrow
                    ? "align-left makeTextClickable adjustTitleWidth m-b-7 d-flex "
                    : "align-left adjustTitleWidth m-b-7 d-flex"
                }
                onClick={() => {
                  navigate(Routes.classListViewRoute);
                }}
              >
                <h1
                  className={
                    config.showLiveEvent || showArrow
                      ? "align-left clear font-h1"
                      : "align-left clear font-h1"
                  }
                >
                  {Translate({ id: "homePage.BrowseClassesTitle" })}
                </h1>
                {showArrow && (
                  <IconRigntArrow id="arrow-4" color={config.primaryColor} cls={"icon-arrow-home"} />
                )}
              </div>
              <h6 className="text-gray font-h6 align-left ">
                {Translate({ id: "homePage.BrowseClassesDescription" })}
              </h6>
            </div>
          )}
          <div
            className={
              !config.showLiveEvent && !showArrow
                ? props.fromLandingPage !== true
                  ? "mobileScroll m-b-10 m-t-15"
                  : "mobileScroll m-t-15"
                : props.fromLandingPage !== true
                ? "mobileScroll m-b-10 m-t-15"
                : "mobileScroll m-t-15"
            }
          >
            <div className="squareImageContainer">
              {categories.length > 0 && (
                <div
                  className={`list ${
                    props.fromLandingPage === true
                      ? "list-browseClasses-landing"
                      : props.fromClassFinder === true
                      ? "list-browseClasses-classFinder"
                      : "list-browseClasses"
                  }`}
                >
                  <div className="">
                    <div
                      className={`cardPos cardSize browseClasses `}
                    >
                      <div
                        data-testid={"browseCategoryClick"}
                        className="imageHolder full-overlay-light imageHolderOverride makeTextClickable"
                        onClick={() => {
                          navigateToBrowseClasses(viewAll);
                        }}
                      >
                        <img
                          className={`browseClasses-all-btn`}
                          src={require(`../../CommonImages/all.png`)}
                          alt="view all"
                        />
                      </div>
                    </div>
                    <div className="titleCategoryName">
                      {Translate({ id: `ClassListViews.ViewAll` })}
                    </div>
                  </div>
                  {categories.map((category, index) => (
                    <div className="">
                      <div
                        key={index}
                        className={`cardPos cardSize browseClasses`
                      }
                      >
                        <div
                          data-testid={"browseCategoryClick"}
                          className="imageHolder full-overlay-light imageHolderOverride makeTextClickable"
                          style={
                            category.imageLink ? {backgroundImage: `url(${appendQueryParameterInURL(category.imageLink, "width", 180)})` } : { backGroundColor }
                          }
                          onClick={() => {
                            navigateToBrowseClasses(category);
                          }}
                        >

                        </div>
                      </div>
                      <div className="titleCategoryName">
                        {Translate({ id: `categoryName.${category.id}` })}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default BrowseClasses;
