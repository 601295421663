import React from "react";
import ChannelsCardWrapper from "./ChannelsCardWarpper";
import { Grid } from "@material-ui/core";
import { Translate } from "utils/Translate";
// import Routes from "components/Router/Routes";
import config from 'assets/config.json';
import IconRigntArrow from 'CommonImages/icon-rightArrow'
import { useNavigate } from "react-router";

const ChannelsHome = (props) => {
  const navigate = useNavigate();
  const cardVersion = config.cardVersion
  //  gotoPlayer=()=>{
  //      navigate({
  //          pathname:Routes.onDemandClassPlayerRoute + "",
  //      })
  //  }


 const viewDetails = () => {
    navigate("/channels", {state: { clubClasses: props.clubClasses }} );
  };
  // const ViewChannel = () => {
  //   navigate({
  //     to: Routes.channelDetailView,
  //     options : { state: { clubClasses: props.clubClasses } },
  //   });
  // };
 
    return (
       <div >       
          <div className="m-t-60 ">
                <div className='m-b-32 m-b-15'>
                    <div className="align-left d-flex makeTextClickable m-b-7" onClick={viewDetails}>
                        <h1 className="align-left font-h1 "> {Translate({ id: "homePage.ChannelsTitle" })} </h1>
                        <IconRigntArrow id='arrow-5' color={config.primaryColor} cls={"icon-arrow-home"} />
                    </div>
                    <h6 className="text-gray font-h6 align-left "  >
                    {Translate({id:"homePage.ChannelsDescription"})}
                    </h6>
                </div>    
         </div>
          <div className="mobileScroll m-t-15">      
          { cardVersion === 0.0 &&<Grid
            container
            justifyContent="flex-start"
            spacing={4}
            className="bg-white m-t-xs-5 flexNoWrap cardList"
          >
            { cardVersion === 0.0 && props.channelData.map(
              (channelData, index) =>
                -index < 3 && (
                  <Grid item lg={4} md={6} sm={12} xs={12} key={index}  className="channelHome">
                    <ChannelsCardWrapper
                        data={channelData}                      
                    />
                  </Grid>
                )
            )}
          </Grid>}
          {cardVersion === 0.1 &&  <div className={`list`}>{props.channelData.map(
              (channelData, index) =>
                -index < 3 && (<ChannelsCardWrapper data={channelData} cardType="live-channel-collection" />)
              )}          
          </div>}
          </div>
          </div>        
    );
}

export default ChannelsHome;
