import React, { Component } from "react";
import "./ClasslistView.css";
import Workout from "hoc/WorkoutContainer"
import "theme/styles/react-select.min.css"
import { workoutResultType } from "utils/constants";
import { Translate } from "utils/Translate";
import { Typography } from "@material-ui/core";
import config from "assets/config";
import Routes from 'components/Router/Routes'
import logger from "utils/logger";
import LoadingClassesShimmer from "components/Shimmer/LoadingClassesShimmer";

class ClasslistViewRecent extends Component {
  state = {
    criteria: "",
    isPrevPlaying: false,
    prevPlayer: null,
    classes: [],
    RecentObjList: null,
    loading: true,
    currentClickedItemId: null
  };

  componentDidMount() {
    //config.ShowLiveConnect && this.props.fetchClubLiveConnectClasses();
    this.props.OngetUserRecentActivity();
  }

  takePreviousPlayer = data => {
    this.setState({ prevPlayer: data });
  };


  GetCollectionTags(itemCollection) {
    if (itemCollection !== null && itemCollection !== undefined) {
      if (itemCollection !== null || itemCollection.length !== 0) {
        var recentObjList = [];
        for (let item, i = 0; (item = itemCollection[i++]);) {
          if (item.trackingResult === workoutResultType.VirtualClass ||
            item.trackingResult === workoutResultType.ClubConnectVideo ||
            item.trackingResult === workoutResultType.LiveStream ||
            item.trackingResult === workoutResultType.Channel
          ) {

            var obj = {
              thumbnail: item.imageUrl,
              title: item.workoutName,
              durationSecond: item.durationSeconds,
              id: item.userWorkoutTag,
              show: true,
              creationDate: item.creationDate,
              trailerLinkMobile: item.trailerLinkMobile,
              trailerLinkWeb: item.trailerLinkWeb,
              equipmentTypes: item.equipmentTypes,
              equipmentNames: item.equipmentNames,
              newProviderId: item.providerID,
              category: item.classCategory,
              description: item.workoutDescription,
              level: item.skill,
              isClubConnect: item.trackingResult === workoutResultType.ClubConnectVideo ? true : false,
              isLiveStream: item.trackingResult === workoutResultType.LiveStream ? true : false,
              liveStreamEventId: item.refTemplateId,
              isChannel: item.trackingResult === workoutResultType.Channel ? true : false,
              mediaType: item.mediaType,
              isUnlocked: item.isUnlocked
            };
            if (this.props.collectionObject) {
              var favColloectionIndex = this.props.collectionObject.findIndex((favColloection) => favColloection.collectionName === "Favourites")
              let collectionItems =this.props.collectionObject[favColloectionIndex].collectionItems
              let isFavorite = collectionItems.findIndex((reqclass) => reqclass?.tag === item?.userWorkoutTag)
              if ( isFavorite > -1) {
                obj.isFav = true
              }
            }
            recentObjList.push(obj);
          }
          this.setState({ RecentObjList: recentObjList });
        }
      }
    }
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.recentActivities !== this.props.recentActivities
    ) {
      this.GetCollectionTags(this.props.recentActivities);

    }

    if (this.props.LSVideoDetails !== prevProps.LSVideoDetails) {
      if (this.props.LSVideoDetails) {
        let video = {
          id: this.props.LSVideoDetails.videoID,
          creationDate: this.props.LSVideoDetails.created_Time,
          trailerLinkWeb: this.props.LSVideoDetails.trailerLink,
          description: this.props.LSVideoDetails.description,
          title: this.props.LSVideoDetails.name,
          thumbnail: this.props.LSVideoDetails.pictures ? this.props.LSVideoDetails.pictures.sizes.link : "",
          durationSecond: this.props.LSVideoDetails.duration,
        };
        var putData = this.props.recentActivities.find((item) => item.userWorkoutTag === video.id)
        if (putData && video.trailerLinkWeb && !putData.trailerLinkWeb && putData.tag) {
          this.props.putWorkoutResult({ ...putData, trailerLinkWeb: video.trailerLinkWeb }, putData.tag)
        }
        this.props.navigate(
          Routes.connectClassPlayerRoute + this.props.LSVideoDetails.videoID,
          {state: { selectedItem: video, isLiveStream: true, isClubConnect: true,prevPath: Routes.recentActivityRoute }});
      }
    }

    if (
      prevProps.collection !== this.props.collection
    ) {
      let obj;

      if (
        this.props.collection !== null &&
        this.props.collection.items.length !== 0
      ) {
        var c = this.props.collection.items[0].virtualClass;

        if (c !== null || c !== undefined) {
          obj = this.buildClassObject(c);          
        }
        if (obj !== null || obj !== undefined) {
          logger(obj)
          this.props.navigate(
            Routes.onDemandClassPlayerRoute + obj.id,

           { state: { selectedItem: obj, prevPath: Routes.recentActivityRoute }});
        }
      }
    }
    if(prevProps.connectClassDataByID !== this.props.connectClassDataByID){
     
      if (this.props.connectClassDataByID) {
        let video = {
          id: this.props.connectClassDataByID[0].tag,
          creationDate: this.props.connectClassDataByID[0].creationDate,
          trailerLinkWeb: this.props.connectClassDataByID[0].trailerLinkWeb,
          description: this.props.connectClassDataByID[0].classDescription,
          title: this.props.connectClassDataByID[0].className,
          thumbnail: this.props.connectClassDataByID[0].imageLink,
          durationSecond: this.props.connectClassDataByID[0].durationSecond,
        };
        
        this.props.navigate(
          Routes.connectClassPlayerRoute + video.id,
          {state: { selectedItem: video, isLiveStream: true,isClubConnect: true, prevPath: Routes.recentActivityRoute }});
      }
    }
    if (
      prevProps.channelResponse !== this.props.channelResponse
    ) {
      let obj;
      if ( this.props.channelResponse !== null ) {
        if (this.props.channelResponse.titleDetails !== null && this.props.channelResponse.channelDetails !== null) {
          let c = this.props.channelResponse.titleDetails;

          if (c !== null || c !== undefined) {
            obj = this.buildClassObject(c);          
          }
          if (obj !== null || obj !== undefined) {
            logger(obj)
            // let channelTag = this.props.channelResponse.channelDetails.tag
            this.props.navigate(
              //pathname: Routes.channelsPlayer + c.tag,
            Routes.onDemandClassPlayerRoute + c.tag,
             { state: {
                  fromRecent: true,
                  selectedItem: {
                      ...this.props.channelResponse.titleDetails
                  }
              },
            });            
          }
        }
      }      
    }

    if (prevProps.classLoadingFailed !== this.props.classLoadingFailed &&
      this.props.classLoadingFailed === true) {
        /// data not found for channel for given class
        /// make on demand search API        
        this.props.onSearchOndemand(this.state.currentClickedItemId, true)
    }
  }

  buildClassObject = (c) => {
    let obj = {
      id: c.tag,
      thumbnail: c.imageLink,
      title: c.className,
      show: c.isActive,
      category: c.classCategory,
      fav: c.favourite,
      match: true,
      durationSecond: c.durationSecond,
      provider_id: c.provider,
      key: c.tag,
      description: c.classDescription
        ? c.classDescription
        : c.description,
      instructor: c.instructor,
      calories: c.calorieBurn,
      alternateLink: c.alternateLink,
      intensity: c.intensity,
      skill: c.skill,
      creationDate: c.creationDate,
      trailerLinkMobile: c.trailerLinkMobile,
      trailerLinkWeb: c.trailerLinkWeb,
      equipmentTypes: c.equipmentTypes,
      equipmentNames: c.equipmentNames,
      newProviderId: c.providerID,
      keywords: c.keywords,
      level: c.skill,
      mediaType: c.mediaType,
      isUnlocked: c.isUnlocked
    };
    return obj
  }

  onClickHandler = (id, classObj) => {
    this.setState({ currentClickedItemId: id })
    if (classObj.isClubConnect) {
      logger(classObj)
      let clubConnectClass = this.props.clubClasses && this.props.clubClasses.data.filter((reqClass) => reqClass.id === classObj.id)
      classObj.durationSecond = clubConnectClass.length > 0 ? clubConnectClass[0].duration : null
      classObj.url = null
      let video = {
        id: classObj.id,
        url: classObj.url,
        creationDate: classObj.creationDate,
        trailerLinkWeb: classObj.trailerLinkWeb,
        description: classObj.description,
        title: classObj.title,
        thumbnail: classObj.thumbnail,
        durationSecond: classObj.durationSecond,
      };
      this.props.navigate(
        Routes.onDemandClassPlayerRoute + classObj.id,
       { state: { fromRecent: true,selectedItem: video, isClubConnect: true, prevPath: Routes.recentActivityRoute },
      });
    }
    else if (classObj.isLiveStream) {
      if (classObj.id) {
        if (classObj.liveStreamEventId) {
          this.props.FetchLSVideoDetails(classObj.liveStreamEventId, classObj.id)
        } else {
          this.props.FetchClubLiveConnect(classObj.id);
        }
      }
    }
  // eslint-disable-next-line eqeqeq
    else if (id != "" || id != null) {
      // eslint-disable-next-line eqeqeq
      this.props.fetchDeeplinkedClassForChannel(id);     
    }
  };

  render() {


    let classes;
    if (
      this.state.RecentObjList === null ||
      this.state.RecentObjList.length === 0
    ) {
      classes = (
        <div className="no-classes-found">
          <Typography variant="body2" className="text-gray">{Translate({ id: "ClassListViews.NoRecentMessage" })}</Typography>
        </div>
      );
    }
    else {
      classes = (
        <div className="list list-live-page clear m-t-40 m-t-xs-5">
          {this.state.RecentObjList.map((c, index) =>
            c.show ? (
              <div xs={12} sm={12} md={6} lg={4}
                className="cardAlign m-t-xs-20" key={Math.random()}>
                <Workout
                  cardType="channel-page-card"
                  collectionType="featured"
                  id={c.id}
                  keywords={c.keywords}
                  isSearchPageCard={true}
                  isPrevPlaying={this.state.isPrevPlaying}
                  prevPlayer={this.state.prevPlayer}
                  displayDate={c.creationDate}
                  takePreviousPlayer={this.takePreviousPlayer}
                  trailerLinkWeb={c.trailerLinkWeb}
                  classCategory={c.category}
                  calories={c.calorieBurn}
                  equipmentTypes={c.equipmentTypes}
                  equipmentNames={c.equipmentNames}
                  thumbnail={`${c.thumbnail}`}
                  title={c.title}
                  key={index}
                  duration={c.durationSecond
                    ? Math.ceil(c.durationSecond / 60)
                    : 55}
                  description={c.description}
                  clicked={() => this.onClickHandler(c.id, c)}
                  show={true}
                  level={c.skill}
                  collectionName={"MyRecentActivity"}
                  isFav={c.isFav}
                  mediaType={c.mediaType}
                  isClubConnect={c.isClubConnect}
                  isLiveStream={c.isLiveStream}
                  isUnlocked={c.isUnlocked}
                />
              </div>
            ) : null
          )}
        </div>
      );
    }


    return (
      <div className="">
        {/* <ProfileWrapper history={this.props.history} /> */}
        <div className="m-t-40 m-t-xs-5">
          <Typography variant="h1" className="review-page-title">
            {Translate({id: "ClassListViews.MyActivity"})}
            </Typography>
        </div>
        {this.props.loading ? <LoadingClassesShimmer myClass="" key={Math.random()} /> :classes }
      </div>
    );
  }
}

export default ClasslistViewRecent
