import React, { useEffect } from "react";
import { Grid, IconButton,Typography } from "@material-ui/core";
import { Translate } from "utils/Translate";
import ClearIcon from '@material-ui/icons/Clear';
import IconRigntArrowInl from "CommonImages/icon-rightArrowInline";
import config from "../../assets/config.json"
import "./Drawer.scss"
import { useDrawer } from '../Drawer';
import { useParams } from "react-router";
import { viewAllCategory } from "utils/constants";

const CategoryDrawer = (props) => { 

    const params = useParams()
    const { metadata, handleClick } = props;
    const { handleDrawerClose } = useDrawer()
    const categories = metadata?.categories;

    const closeHandler = () => {
        handleDrawerClose()
    }

    useEffect(()=>{
        isVisible(params.id)
    }, [document.getElementById(params.id)])
    
    function isVisible(id) {
        // this function is in use to scroll category tabs
        try {
            const element = document.getElementById(id)
            const rect = element?.getBoundingClientRect();
            //check for clicked category tab is not in the view port
            
            if ((rect.top >= 0 &&
                rect.bottom >= window.innerHeight)) {
                // scrolling to top
                document.querySelector(".drawer-scroll").scrollTo({ top: (rect.bottom - window.innerHeight + rect.height ), behavior: 'smooth' })
            }
        } catch (error) {
            return () => { }
        }
    }

    return(
        <div className="customFilter" onLoad={isVisible(params.id)} data-testid="category-drawer">
        <Grid container className="drawer-title">
            <Grid item lg={8} md={8} sm={6} xs={6}>
                <Typography variant="h2" className="m-t-15" style={{ fontSize: "28px" }}>
                    {Translate({ id: "ClassListViews.Categories", defaultMessage: "Categories"})}
                </Typography>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={6}>
                <div className="text-right">
                    <IconButton
                        onClick={closeHandler}
                        className=""
                        style={{
                            paddingBottom: "0px",
                            background: "none",
                            marginTop: "2px",
                            paddingRight: "16px"
                        }}>
                        <ClearIcon className="text-black" style={{ fontSize: "2rem" }} />
                    </IconButton>
                </div>
            </Grid>
        </Grid>
        <div className="drawer-scroll">
            <div className="drawer-container" onClick={() => {
                handleClick(viewAllCategory.id)
                handleDrawerClose()
                }}>
                <Typography
                    Typography
                        className={`makeTextClickable text ${params.id === (viewAllCategory.id).toString() && "dynamiclinks"}`}
                >
                    {Translate({id: `ClassListViews.ViewAll` })}
                </Typography>
                <IconRigntArrowInl
                    color={config.primaryColor}
                    cls="arrow-field arrow-size"
                />
            </div>
        {categories?.map((category, index) => (
            <div className="drawer-container" id={category.id} onClick={()=>{
                handleClick(category.id)
                handleDrawerClose()
            }}>
                <Typography
                    Typography                  
                    className={`makeTextClickable text ${params.id === (category.id).toString() && "dynamiclinks" }`}
                >
                    {Translate({ id: `categoryName.${category.id}`})}
                </Typography>
                <IconRigntArrowInl
                    color={config.primaryColor}
                    cls="arrow-field arrow-size"
                />
            </div>
        ))}
        </div>
    </div>)
}

export default CategoryDrawer

