import React, { useEffect, Fragment, useState } from 'react';
import LiveStreamHome from "../LiveStreaming/LiveStreamHome";
import BrowseClasses from "./BrowseClasses";
import Routes from '../../components/Router/Routes';
import LiveScheduleShimmer from '../../components/Shimmer/LiveScheduleShimmer';
import BrowseClassesShimmer from "../../components/Shimmer/BrowseClassesShimmer";
import { useDefaultClassesPage } from "../../hooks/useDefaultClassesPage"
import { useNavigate } from 'react-router';

const config = require("../../assets/config.json");

function DefaultClassesPage(props) {
    const { useDefaultClassesPageState, useDefaultClassesPageDispatch } = useDefaultClassesPage()
    const { LSHomeClasses, liveConnectLoading, metadata, entitlement } = useDefaultClassesPageState()
    const { fetchLSConnectHome } = useDefaultClassesPageDispatch()
    const [categories, setCategories] = useState([])
    const navigate = useNavigate();
    useEffect(() => {
        if (entitlement?.live) {
            fetchLSConnectHome()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (metadata) {
            setCategories(metadata.categories)
        }
    }, [metadata])

    return (
        categories && categories.length > 0 &&
            <div className="page-container" data-testid={(entitlement?.live && entitlement?.category)?"live-cateorgy":"live"}>
                {entitlement?.live ? 
                    (liveConnectLoading ? <div className='m-t-30'><LiveScheduleShimmer /></div> : entitlement?.live && LSHomeClasses && <LiveStreamHome clubClasses={LSHomeClasses && LSHomeClasses.data} />) : null }
                {entitlement?.category ? 
                    (liveConnectLoading ? <BrowseClassesShimmer /> : <BrowseClasses fromClassFinder={true} />) : null}
            </div>
    )
}

export default DefaultClassesPage
