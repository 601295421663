import React, { useEffect, useState } from "react";
import { Translate } from "utils/Translate";
import Routes from "components/Router/Routes";
import config from "assets/config.json";
import IconRigntArrow from "CommonImages/icon-rightArrow";
import { useNavigate } from "react-router";
import Workout from "hoc/WorkoutContainer";

function Classes(props) {

    const [collectionData, setcollectionData] = useState(props.collectionArray);
    const section = props.section

    const navigate = useNavigate();
    const cardVersion = config.cardVersion;
    useEffect(() => {
        let collectionArray = props.collectionArray;
        setcollectionData(collectionArray);
    }, [props.collectionArray]);

    const routes = {
        "fav": "favClassRoute",
        "res": "recentActivityRoute",
        "playlist": "userPlayList",

    }

    const clickHandler = () => {
        const redirectRoute = routes[section]
        navigate(Routes[redirectRoute]);
    };

    const handlePlaylist = (item) => {
        navigate(Routes.userPlayList + "/" + item.playlistId);
    }

    const handleRecent = (item) => {
        let obj = {
            id: item.userWorkoutTag,
            thumbnail: item.imageUrl,
            title: item.workoutName,
            category: item.classCategories,
            durationSecond: item.durationSecond,
            key: item.tag,
            description: item.workoutDescription,
            instructor: item.instructor,
            calories: item.calorieBurn,
            alternateLink: item.alternateLink,
            intensity: item.intensityLevel,
            skill: item.skillLevel,
            creationDate: item.creationDate,
            trailerLinkWeb: item.trailerLinkWeb,
            newProviderId: item.providerID,
            keywords: item.keywords,
            level: item.skill,
            mediaType: item.mediaType,
            isUnlocked: item.isUnlocked
        };
        navigate(
            Routes.onDemandClassPlayerRoute + item.userWorkoutTag,
            {
                state: { fromRecent: true, selectedItem: obj, isClubConnect: true, prevPath: Routes.recentActivityRoute },
            })
    }

    const handleFavorite = (item) => {
        navigate(
            Routes.onDemandClassPlayerRoute + item.tag,
            { state: { selectedItem: item, prevPath: Routes.favClassRoute } });
    }

    const playlist = (item) => { 
        return <Workout
        collectionType ='featured'
        thumbnail = {item?.imageLink}
        title={item?.title}
        id={item.tag}
        cardType='live-channel-collection'
        isSearchPageCard={false}
        isSliderWorkout={true}
        section={props.section}
        totalClasses= {item.totalItems}
        clicked= {()=>handlePlaylist(item)}
        isUnlocked={true}
        /> 
    }

    const favorites = (item) => {
        return <Workout
            collectionType='featured'
            thumbnail={item?.imageLink}
            title={item?.className}
            duration={item.durationSecond
                ? Math.ceil(item.durationSecond / 60)
                : 55}
            description={item.description}
            id={item.tag}
            classCategory={item.classCategory}
            level={item.skill}
            cardType='live-channel-collection'
            isSearchPageCard={false}
            isSliderWorkout={true}
            displayDate={item.scheduleDate}
            trailerLinkMobile={item.trailerLinkMobile}
            trailerLinkWeb={item.trailerLinkWeb}
            calories={item.calorieBurn}
            equipmentTypes={item.equipmentTypes}
            equipmentNames={item.equipmentNames}
            isFav={item.fav}
            mediaType={item.mediaType}
            isUnlocked={item.isUnlocked}
            collectionName={"Favourites"}
            clicked={() => handleFavorite(item)}
        />
    }

    const recentActivities = (item) => {
        return <Workout
            collectionType='featured'
            thumbnail={item?.imageUrl}
            title={item?.workoutName}
            duration={item.durationSeconds
                ? Math.ceil(item.durationSeconds / 60)
                : 55}
            description={item.workoutDescription}
            id={item.tag}
            classCategory={item.classCategories}
            level={item.skill}
            cardType='live-channel-collection'
            isSearchPageCard={false}
            isSliderWorkout={true}
            displayDate={item.creationDate}
            trailerLinkWeb={item.trailerLinkWeb}
            calories={item.calorieBurn}
            mediaType={item.mediaType}
            isUnlocked={item.isUnlocked}
            clicked={() => handleRecent(item)}
            collectionName={"Favourites"}
        />
    }

    const sections = {
        "fav": favorites,
        "res": recentActivities,
        "playlist": playlist
    }

    const getSectionType = (item) => {
        const types = sections[section]
        return (types && types(item))
    }

    const heading = {
        "fav": { heading: "FavTitle", desc: "MyFavoriteDescription"},
        "res": { heading: "MyActivity", desc: "MyActivityDescription"},
        "playlist": { heading: "Playlists", desc: "MyPlaylistDescription"}
    }

    const sectionHeading = () => {
        const title = heading[section].heading
        const contexts = {
            "fav": "ClassListViews",
            "res": "ClassListViews",
            "playlist": "Playlist"
        } 
        return Translate({ id: `${contexts[section]}.${title}` }) 
    }

    const sectionDescription = () => {
        const description = heading[section].desc
        return Translate({ id: `homePage.${description}` }) 
    }
    
    return collectionData &&
        collectionData.length > 0 ? (
        <>
            <div className="m-t-60 ">
                <div className="m-b-25 m-b-15">
                    <div
                        data-testid={"collectionTitleClick"}
                        className="align-left d-flex makeTextClickable m-b-7"
                        onClick={clickHandler}
                        role='button'
                        aria-label={section}
                    >
                        <h1
                            className="align-left font-h1 "
                            data-testid="homePageCollectionTitle"
                        >   
                            {sectionHeading()}
                        </h1>
                        <IconRigntArrow id="arrow-1" color={config.primaryColor} cls={"icon-arrow-home"} role='button'
                        aria-label={section}/>
                    </div>
                    <h6 className="text-gray font-h6 align-left ">
                        {sectionDescription()}
                    </h6>

                </div>
            </div>
            <div className="list mobileScroll m-t-8">
                    {cardVersion === 0.1 && collectionData.map((item) => 
                            getSectionType(item))
                        }
            </div>
        </>
    ) : null;
}

export default Classes;
