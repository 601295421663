import React, {  useEffect, useReducer } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import Filter from './FilterKeywords';
import Workout from '../../../hoc/WorkoutContainer';
import Routes from '../../../components/Router/Routes'
import { Translate } from '../../../utils/Translate';
import InfiniteScroll from "react-infinite-scroller";
import LoadingClassesShimmer from "../../../components/Shimmer/LoadingClassesShimmer";
import { LAZY_LOADING_PAGE_SIZE } from '../../../utils/constants';
import { isSeamlessMode } from '../../../utils/utilityfunctions';
import { useOnDemandFilter } from "../../../hooks/useOnDemandFilter"
import LSListShimmer from '../../../components/Shimmer/LSListShimmer';
import { useNavigate } from 'react-router';
const config = require("../../../assets/config.json")

const OnDemandFilter = (props) => {
    const navigate = useNavigate();
    const { useOnDemandFilterState, useOnDemandFilterDispatch , onDemandFilterReducer } = useOnDemandFilter();
    
    const { 
        tempFilterSearchClasses, selectedFilters, loading,
        filteredClassSearchData, filteredSkill, filteredDuration,
        filteredKeywords, filteredSubCategory, filteredFocusArea,
        filteredEquipments, filterSortBy, filterSearchText,
        filteredIntensity, filteredLanguage, filteredTopKeywords,
        pageCount, paginatedData, classesSearchDataOnDemand, 
        tempClassSearchDataOnDemand,topKeywordsOnDemand, searchedOnDemandClassesObj,
        resetInProgress, isAuthenticated, filteredMediaType
    } = useOnDemandFilterState();
    
    const { searchOnDemandClassWithNoFilter, searchOnDemandClassWithFilter, setClassesSearchOnDemandData,
        setOnDemandTopKeywords, setTempClassSearchOnDemandData, setOnDemandPaginatedData,
        setFilteredTopKeywords, setPageCount, setOnDemandKeywords,setSelectedFiltersOnDemand,
        resetOnDemandFilters, setFilteredClassSearchData  } = useOnDemandFilterDispatch();
        
    const { initialState, useOnDemandFilterReducer, setReducerData  } = onDemandFilterReducer;
    const [onDemandFilterState ,onDemandFilterDispatch] = useReducer(useOnDemandFilterReducer, initialState );
        
    const {classesCount,showNoDataFound,noDataFromApi,hasMoreData,loadMoreAllCalled} = onDemandFilterState        

    useEffect(() => {

        if (searchedOnDemandClassesObj === null || searchedOnDemandClassesObj === "")
            fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (resetInProgress) fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetInProgress])

    const fetchData = () => {
        if (selectedFilters?.length > 0 || filterSearchText?.length > 0)
            fetchDataByFilters(selectedFilters, true)
        else if (pageCount === 0) {
            searchOnDemandClassWithNoFilter(config.providerId)
        }
    }

    useEffect(() => {
        if (searchedOnDemandClassesObj) {
            if (searchedOnDemandClassesObj.items && searchedOnDemandClassesObj.items.length > 0) {
                setClassesSearchOnDemandData(searchedOnDemandClassesObj.items)
            }
            else {
                setReducerData(onDemandFilterDispatch,{noDataFromApi: true})

            }
        }
        if (searchedOnDemandClassesObj && searchedOnDemandClassesObj.items && searchedOnDemandClassesObj.items.length > 0) {
            if (selectedFilters.length === 0)
                setOnDemandTopKeywords(searchedOnDemandClassesObj.metaData && searchedOnDemandClassesObj.metaData.topLabels)
                setReducerData(onDemandFilterDispatch, { 
                    classesCount : (searchedOnDemandClassesObj.items.length),
                    noDataFromApi : false
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchedOnDemandClassesObj])

    useEffect(() => {
        if (tempFilterSearchClasses && filterSearchText.length > 0) {
            setReducerData(onDemandFilterDispatch, { classesCount: (tempFilterSearchClasses.items.length) })
            setTempClassSearchOnDemandData(tempFilterSearchClasses.items)
            fetchTopKeywords(tempFilterSearchClasses.items)
            if (tempFilterSearchClasses.items.length === 0)
                setReducerData(onDemandFilterDispatch,{showNoDataFound: true})
            else
                setReducerData(onDemandFilterDispatch,{showNoDataFound: false})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tempFilterSearchClasses])

    useEffect(() => {
        if (tempClassSearchDataOnDemand?.length === 0 && selectedFilters?.length === 0 && classesSearchDataOnDemand?.length > 0 && pageCount === 0) {
            setOnDemandPaginatedData(classesSearchDataOnDemand.slice(0, LAZY_LOADING_PAGE_SIZE))
        }
        else if (tempClassSearchDataOnDemand?.length > 0 && (filterSearchText?.length > 0 || selectedFilters?.length > 0)) {
            setOnDemandPaginatedData(tempClassSearchDataOnDemand.slice(0, LAZY_LOADING_PAGE_SIZE))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classesSearchDataOnDemand, tempClassSearchDataOnDemand])

    const fetchTopKeywords = (classesSearchDataOnDemand) => {

        let tempTopKeywords = []
        for (let i = 0; i < classesSearchDataOnDemand.length; i++) {
            if (tempTopKeywords.length === 0 && classesSearchDataOnDemand[i].virtualClass.labels.length === 1) {
                tempTopKeywords.push({ keyword: classesSearchDataOnDemand[i].virtualClass.labels[0], count: 1 })
            }
            else {
                if (classesSearchDataOnDemand[i].virtualClass.labels.length === 1) {
                    let temp = [...tempTopKeywords], matched = false
                    for (let x = 0; x < temp.length; x++) {
                        if (tempTopKeywords[x].keyword === classesSearchDataOnDemand[i].virtualClass.labels[0]) {
                            tempTopKeywords[x] = { ...tempTopKeywords[x], count: parseInt(tempTopKeywords[x].count) + 1 }
                            matched = true
                            break;
                        }
                    }
                    if (!matched) {
                        tempTopKeywords.push({ keyword: classesSearchDataOnDemand[i].virtualClass.labels[0], count: 1 })
                    }
                }
                else {
                    let temp = [...tempTopKeywords], matched = false
                    for (let j = 0; j < classesSearchDataOnDemand[i].virtualClass.labels.length; j++) {
                        matched = false
                        for (let y = 0; y < temp.length; y++) {
                            if (tempTopKeywords[y].keyword === classesSearchDataOnDemand[i].virtualClass.labels[j]) {
                                tempTopKeywords[y] = { ...tempTopKeywords[y], count: parseInt(tempTopKeywords[y].count) + 1 }
                                matched = true
                                break;
                            }
                        }
                        if (!matched) {
                            tempTopKeywords.push({ keyword: classesSearchDataOnDemand[i].virtualClass.labels[j], count: 1 })
                        }
                    }
                }
            }
        }

        let sortTopKeywords = tempTopKeywords.sort(function (a, b) {
            return b.count - a.count
        })
        let sortedTopKeywords = []
        for (let z = 0; z < sortTopKeywords.length; z++) {
            sortedTopKeywords.push(sortTopKeywords[z].keyword)
        }
        if (sortedTopKeywords.length > 3)
            sortedTopKeywords = sortedTopKeywords.splice(0, 3)

        let temp = [...topKeywordsOnDemand]
        let prevTopKeywords = filteredTopKeywords.length > 0 ? filteredTopKeywords : temp.splice(0, 3)
        let tempsortedTopKeywords = [...sortedTopKeywords]
        let tempprevTopKeywords = [...prevTopKeywords]
        let sortedTKeywordsByWord = tempsortedTopKeywords.sort()
        let sortedPrevTopKeywordsByWord = tempprevTopKeywords.sort()

        if (JSON.stringify(sortedTKeywordsByWord) !== JSON.stringify(sortedPrevTopKeywordsByWord)) {
            setFilteredTopKeywords(sortedTopKeywords)
        }
    }

    const fetchDataByTopKeywords = (filterValue, filterType = 'Keywords',formTopKeyWords=false) => {
        setPageCount(0)
        setOnDemandPaginatedData([])
        let tempFilters = [...selectedFilters], sameValueSelected = false
        if (selectedFilters.length === 0) {
            tempFilters.push({ filterType, filterValue })
            setOnDemandKeywords(filterValue)
        }
        else {
            for (let index = 0; index < selectedFilters.length; index++) {
                if (selectedFilters[index].filterType === filterType) {
                    if (selectedFilters[index].filterValue === filterValue)
                        sameValueSelected = true
                    tempFilters.splice(index, 1);
                }
            }
            if (!sameValueSelected) {
                tempFilters.push({ filterType, filterValue })
                setOnDemandKeywords(filterValue)
            }
            else {
                setOnDemandKeywords('')
            }
        }
        setSelectedFiltersOnDemand(tempFilters)
        fetchDataByFilters(tempFilters,false,false,formTopKeyWords)
    }

    const resetAllFiltersValues = () => {
        setTempClassSearchOnDemandData([])
        setReducerData(onDemandFilterDispatch, {classesCount:(classesSearchDataOnDemand.length)})
        resetOnDemandFilters()
        if (classesSearchDataOnDemand.length > 0)
            setReducerData(onDemandFilterDispatch,{showNoDataFound: false})

    }

    const fetchDataByFilters = (filters, fromNavigation = false, reset = false,fromTopKeyWords=false) => {
        setPageCount(0)
        setOnDemandPaginatedData([])
        if (reset === true || (filterSearchText.length === 0 && filters.length === 0)) {
            resetAllFiltersValues()
        }
        else if (filterSearchText.length > 0) {
            fetchDataByFilterSearch(filterSearchText)
        }
        else {
            if (fromNavigation)
                getFilteredData(filteredClassSearchData, filters, fromTopKeyWords)
            else
                getFilteredData(classesSearchDataOnDemand, filters, fromTopKeyWords)
        }
    }

    const getFilteredData = (classesSearchDataOnDemand, filters, fromTopKeyWords) => {
        let tempData = [],
          skillLevels = [],
          intensityLevels = [],
          equipment = "",
          keyword = "",
          focusArea = "",
          classLanguage = "",
          sortBy = "",
          subCategory = "",
          duration = "",
          mediaType=''
        for (let j = 0; j < filters.length; j++) {
            switch (filters[j].filterType.toString().toLowerCase()) {
              case `keywords`:
                keyword = filters[j].filterValue;
                break;
              case `duration`:
                duration = filters[j].filterValue;
                break;
              case `skill level`:
                skillLevels = filters[j].filterValue.split(",");
                break;
              case `focus area`:
                focusArea = filters[j].filterValue;
                break;
              case `equipment`:
                equipment = filters[j].filterValue;
                break;
              case `intensity`:
                intensityLevels = filters[j].filterValue.split(",");
                break;
              case `class language`:
                classLanguage = filters[j].filterValue;
                break;
              case `sub category`:
                subCategory = filters[j].filterValue;
                break;
              case `sort`:
                sortBy = filters[j].filterValue;
                break;
              case `media type`:
                mediaType = filters[j].filterValue;
                break;
              default:
                return null;
            }
        }


        for (let i = 0; i < classesSearchDataOnDemand.length; i++) {
            let allow = true, found = true
            //Keywords
            if (keyword.length > 0 && classesSearchDataOnDemand[i].virtualClass.labels && classesSearchDataOnDemand[i].virtualClass.labels.length > 0) {
                for (let index = 0; index < classesSearchDataOnDemand[i].virtualClass.labels.length; index++) {
                    if (!(classesSearchDataOnDemand[i].virtualClass.labels[index].includes(keyword))) {
                        found = false
                    }
                    else {
                        found = true
                        break;
                    }
                }
                if (!found)
                    allow = false
            }
            else if (keyword.length > 0 && classesSearchDataOnDemand[i].virtualClass.labels && classesSearchDataOnDemand[i].virtualClass.labels.length === 0) {
                allow = false
            }
            else if (keyword.length > 0 && !classesSearchDataOnDemand[i].virtualClass.labels) {
                allow = false
            }
            //Duration
            if (duration.length > 0 && classesSearchDataOnDemand[i].virtualClass.durationSecond) {
                let timeMin, timeMax;
                switch (duration) {
                    case '0,600':  //10 min
                        timeMin = 0; timeMax = 600
                        break;
                    case '601,1200':  //20 min
                        timeMin = 601; timeMax = 1200
                        break;
                    case '1201,1800':   //30 min
                        timeMin = 1201; timeMax = 1800
                        break;
                    case '1801,18000':    //40+min
                        timeMin = 1801; timeMax = 18000
                        break;
                    default :
                        return null
                }
                if (!(parseFloat(classesSearchDataOnDemand[i].virtualClass.durationSecond) >= parseInt(timeMin) && parseFloat(classesSearchDataOnDemand[i].virtualClass.durationSecond) <= (timeMax))) {
                    allow = false
                }
            }
            else if (duration.length > 0 && !classesSearchDataOnDemand[i].virtualClass.durationSecond) {
                allow = false
            }
            //Skill Levels
            if (skillLevels.length > 0 && classesSearchDataOnDemand[i].virtualClass.skill !== undefined) {
                if (!(classesSearchDataOnDemand[i].virtualClass.skill >= skillLevels[0] && classesSearchDataOnDemand[i].virtualClass.skill <= skillLevels[1])) {
                    allow = false
                }
            }
            else if (skillLevels.length > 0 && classesSearchDataOnDemand[i].virtualClass.skill === undefined) {
                allow = false
            }
            //Intensity levels
            if (intensityLevels.length > 0 && classesSearchDataOnDemand[i].virtualClass.intensity !== undefined) {
                if (!(classesSearchDataOnDemand[i].virtualClass.intensity >= intensityLevels[0] && classesSearchDataOnDemand[i].virtualClass.intensity <= intensityLevels[1])) {
                    allow = false
                }
            }
            else if (intensityLevels.length > 0 && classesSearchDataOnDemand[i].virtualClass.intensity === undefined) {
                allow = false
            }
            //Class SubCategory
            if (subCategory.length > 0 && classesSearchDataOnDemand[i].virtualClass.classSubCategory) {
                if (!(classesSearchDataOnDemand[i].virtualClass.classSubCategory.toLowerCase() === subCategory.toLowerCase())) {
                    allow = false
                }
            }
            else if (subCategory.length > 0 && !classesSearchDataOnDemand[i].virtualClass.classSubCategory) {
                allow = false
            }
            //Class Language
            if (classLanguage.length > 0 && classesSearchDataOnDemand[i].virtualClass.languageName) {
                if (!(classesSearchDataOnDemand[i].virtualClass.languageName.toLowerCase() === classLanguage.toLowerCase())) {
                    allow = false
                }
            }
            else if (classLanguage.length > 0 && !classesSearchDataOnDemand[i].virtualClass.languageName) {
                allow = false
            }
            //FocusArea
            if (focusArea.length > 0 && classesSearchDataOnDemand[i].virtualClass.focusArea && classesSearchDataOnDemand[i].virtualClass.focusArea.length > 0) {
                for (let index = 0; index < classesSearchDataOnDemand[i].virtualClass.focusArea.length; index++) {
                    if (!(classesSearchDataOnDemand[i].virtualClass.focusArea[index].includes(focusArea))) {
                        found = false
                    }
                    else {
                        found = true
                        break;
                    }
                }
                if (!found)
                    allow = false
            }
            else if (focusArea.length > 0 && classesSearchDataOnDemand[i].virtualClass.focusArea && classesSearchDataOnDemand[i].virtualClass.focusArea.length === 0) {
                allow = false
            }
            else if (focusArea.length > 0 && !classesSearchDataOnDemand[i].virtualClass.focusArea) {
                allow = false
            }
            //Equipments
            if (equipment.length > 0 && classesSearchDataOnDemand[i].virtualClass.equipmentNames && classesSearchDataOnDemand[i].virtualClass.equipmentNames.length > 0) {
                for (let index = 0; index < classesSearchDataOnDemand[i].virtualClass.equipmentNames.length; index++) {
                    if (!(classesSearchDataOnDemand[i].virtualClass.equipmentNames[index].includes(equipment))) {
                        found = false
                    }
                    else {
                        found = true
                        break;
                    }
                }
                if (!found)
                    allow = false
            }
            else if (equipment.length > 0 && classesSearchDataOnDemand[i].virtualClass.equipmentNames && classesSearchDataOnDemand[i].virtualClass.equipmentNames.length === 0) {
                allow = false
            }
            else if (equipment.length > 0 && !classesSearchDataOnDemand[i].virtualClass.equipmentNames) {
                allow = false
            }
          if (mediaType !== "") {
              if (!(classesSearchDataOnDemand[i].virtualClass.mediaType == mediaType)
              ) {
                allow = false;
              }
          } 
            if (allow)
                tempData.push(classesSearchDataOnDemand[i])
        }
        !fromTopKeyWords && fetchTopKeywords(tempData)
        setReducerData(onDemandFilterDispatch, {classesCount:(tempData.length)})
        if (sortBy.length > 0) {
            let sortedData = tempData.sort((a, b) => {
                if (sortBy === "Newest")
                    return new Date(b.virtualClass.scheduleDate) - new Date(a.virtualClass.scheduleDate)
                else
                    return new Date(a.virtualClass.scheduleDate) - new Date(b.virtualClass.scheduleDate)
            });
            setTempClassSearchOnDemandData(sortedData)
        }
        else
            setTempClassSearchOnDemandData(tempData)

        if (tempData.length === 0)
            setReducerData(onDemandFilterDispatch,{showNoDataFound: true})

        else
            setReducerData(onDemandFilterDispatch,{showNoDataFound: false})

        setFilteredClassSearchData(tempData)
        window.scrollTo(0, 0);

    }

    const fetchDataByFilterSearch = (searchText, fromSearch = true) => {
        searchOnDemandClassWithFilter(filteredKeywords, filteredFocusArea, filteredSkill,filteredDuration, filteredEquipments, filteredIntensity, searchText, filterSortBy , filteredLanguage, filteredSubCategory, fromSearch,config.providerId, filteredMediaType)
    }

    const onClickHandler = data => {
        navigate( Routes.onDemandClassPlayerRoute + data.tag,
            { state: { selectedItem: data } } );
    };

    window.onscroll = () => {
        if(isSeamlessMode()){
            if (document.querySelector(".staticBlockEw")) {
                if (window.scrollY > 96) {
                    document.querySelector(".staticBlockEw").className = "staticBlockEw scroll";
                } else {
                    document.querySelector(".staticBlockEw").className = "staticBlockEw";
                }
            }
        }
        else{
            if (document.querySelector(".staticBlock")) {
                if (window.scrollY > 96) {
                    document.querySelector(".staticBlock").className = "staticBlock scroll";
                } else {
                    document.querySelector(".staticBlock").className = "staticBlock";
                }
            }
        }   
    }

    const resetFilterData = () => {
        fetchDataByFilters([], false, true)
    }

    const loadMore = () => {

        let toFilterClasses = []
        if (tempClassSearchDataOnDemand.length === 0 && selectedFilters.length === 0) {
            toFilterClasses = classesSearchDataOnDemand
        }
        else
            toFilterClasses = tempClassSearchDataOnDemand
        setTimeout(() => {
            if (!loadMoreAllCalled) {
                setReducerData(onDemandFilterDispatch,{loadMoreAllCalled: true})

                if (pageCount < toFilterClasses.length) {
                    let data = toFilterClasses.slice(0, pageCount + LAZY_LOADING_PAGE_SIZE)
                    setPageCount(pageCount + LAZY_LOADING_PAGE_SIZE)
                    setOnDemandPaginatedData(data)

                }
                else {
                    setReducerData(onDemandFilterDispatch,{hasMoreData: false})

                }
            }
            else {
                setReducerData(onDemandFilterDispatch,{loadMoreAllCalled: false})

            }
        }, 1000);
    }

    useEffect(() => {
        if ((tempClassSearchDataOnDemand?.length > 0 || classesSearchDataOnDemand?.length > 0) && paginatedData?.length > 0) {
            let allClasses = tempClassSearchDataOnDemand?.length > 0 ? tempClassSearchDataOnDemand : classesSearchDataOnDemand
            if (allClasses.length <= paginatedData.length) {
                setReducerData(onDemandFilterDispatch,{hasMoreData: false})

            }
            else {
                setReducerData(onDemandFilterDispatch,{hasMoreData: true})

            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginatedData])

    const checkFavStatus = (favoriteId) => {
        let classes = JSON.parse(JSON.stringify(paginatedData));
        let classIndex = classes.findIndex(particularclass => particularclass.classTag === favoriteId)
        if (classIndex !== -1) {
            classes[classIndex].virtualClass.favourite = !classes[classIndex].virtualClass.favourite
            setOnDemandPaginatedData(classes)
        }

        if (tempClassSearchDataOnDemand.length === 0 && selectedFilters.length === 0 && classesSearchDataOnDemand.length > 0 && pageCount === 0) {
            let classIndex = classesSearchDataOnDemand.findIndex(particularclass => particularclass.classTag === favoriteId)
            if (classIndex !== -1) {
                classesSearchDataOnDemand[classIndex].virtualClass.favourite = !classesSearchDataOnDemand[classIndex].virtualClass.favourite
            }
        }
        else if (tempClassSearchDataOnDemand.length > 0 && (filterSearchText.length > 0 || selectedFilters.length > 0)) {
            let classIndex = tempClassSearchDataOnDemand.findIndex(particularclass => particularclass.classTag === favoriteId)
            if (classIndex !== -1) {
                tempClassSearchDataOnDemand[classIndex].virtualClass.favourite = !tempClassSearchDataOnDemand[classIndex].virtualClass.favourite
            }
        }
    }

    return (
        <div className="page-container align-left">
            {/* <AppBar position="relative" color="inherit" className="mobileScrollParent ipadScrollParent"> */}
                {searchedOnDemandClassesObj && searchedOnDemandClassesObj.items && searchedOnDemandClassesObj.items.length > 0 && 
                <div className={isSeamlessMode()?"staticBlockEw":"staticBlock"}>
                    <Grid container justifyContent="flex-start" className="m-t-xs-15 topKeySpace">
                        <Filter
                            categoryName={''}
                            topKeywords={showNoDataFound ? [] : filteredTopKeywords.length > 0 ? filteredTopKeywords : topKeywordsOnDemand}
                            initialFilterMetaData={searchedOnDemandClassesObj && searchedOnDemandClassesObj.metaData}
                            fetchDataByFilters={fetchDataByFilters}
                            classesCount={classesCount}
                            fetchDataByTopKeywords={fetchDataByTopKeywords}
                            fetchDataByFilterSearch={fetchDataByFilterSearch}
                        />
                    </Grid>
                </div>}
                {!showNoDataFound &&
                    <InfiniteScroll
                        pageStart={0}
                        datalength={LAZY_LOADING_PAGE_SIZE}
                        loadMore={loadMore}
                        hasMore={hasMoreData}
                        loader={hasMoreData && !loading ? <LoadingClassesShimmer key={Math.random()}/> : null}
                    >
                        <div className="collectionShimmer m-bt-lg-30 m-t-xs-20">
                            {
                                loading ? <LSListShimmer onDemand="true" />:
                                    // <Grid container justifyContent="flex-start" spacing={4} className="">
                                    <div className="list list-live-page">
                                        {paginatedData?.length > 0 && paginatedData.map((item, index) => (
                                            // <Grid key={Math.random()} item lg={4} md={6} sm={12} xs={12} >
                                                <Workout
                                                    key={Math.random()}
                                                    //keywords={item.keywords}
                                                    isSearchPageCard={false}
                                                    displayDate={item.virtualClass.scheduleDate}
                                                    trailerLinkWeb={item.virtualClass.trailerLinkWeb}
                                                    trailerLinkMobile={item.virtualClass.trailerLinkMobile}
                                                    isSliderWorkout={true}
                                                    history={props.history}
                                                    showFav={isAuthenticated ? true : false}
                                                    thumbnail={`${item.virtualClass.imageLink}`}
                                                    equipmentTypes={item.virtualClass.equipmentTypes}
                                                    equipmentNames={item.virtualClass.equipmentNames}
                                                    isFav={item.virtualClass.favourite}
                                                    title={item.virtualClass.className}
                                                    duration={item.virtualClass.durationSecond
                                                        ? Math.ceil(item.virtualClass.durationSecond / 60)
                                                        : 55}
                                                    description={item.virtualClass.classDescription}
                                                    show={true}
                                                    calories={item.virtualClass.calorieBurn}
                                                    id={item.virtualClass.tag}
                                                    classCategory={item.virtualClass.classCategory}
                                                    mediaType={item.virtualClass.mediaType}
                                                    // key={categoryId + index}
                                                    clicked={() => onClickHandler(item.virtualClass)}
                                                    // collectionName={categoryId}
                                                    level={item?.virtualClass.skill ? item.virtualClass.skill: item.skill }
                                                    favCallback={() => { checkFavStatus(item.virtualClass.tag) }}
                                                    cardType = "channel-page-card"
                                                    collectionType ="featured"
                                                    isUnlocked={item.virtualClass.isUnlocked}
                                                />
                                            // </Grid>
                                            ))}
                                    {/* </Grid> */}
                                </div>
                            }
                        </div>
                    </InfiniteScroll>
                }

                {
                    (showNoDataFound || noDataFromApi) && <Grid container justifyContent="flex-start" spacing="4" >
                        <Typography className="text-gray padding16 m-t-55 m-t-xs-32"
                            style={{ textAlign: 'center', width: '100%' }}
                        >
                            {Translate({ id: "filter.NoClassesFound" })}
                        </Typography>
                        {!noDataFromApi && <div className="padding16 m-t-xs-15 m-t-30" style={{ textAlign: 'center', width: '100%' }}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                className="button m-t-15 btn-default"
                                style={{ height: 48, borderRadius: 35, }}
                                onClick={resetFilterData}
                            >
                                <Typography variant="button" style={{ color: "secondary" }}>
                                    {Translate({ id: "filter.ShowAllClasses" })}
                                </Typography>
                            </Button>
                        </div>}
                    </Grid>
                }

            {/* </AppBar > */}
        </div >
    );
}



export default OnDemandFilter;
