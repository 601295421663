/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { Fragment, useState, useEffect } from 'react';
import ClubList from '../../hoc/ClubListContainer';
import Livestreamlist from '../LiveStreaming/LiveStreamList';
import { Grid, Typography } from "@material-ui/core";
import { Translate } from "../../utils/Translate";
import Routes from '../../components/Router/Routes';
// import {saveSelectedDate} from "../../store/actions/ondemand";
import { useWrapperList } from "../../hooks/useWrapperList";
import { useLocation, useNavigate } from 'react-router';

var config = require("../../assets/config.json");

function WrapperList(props) {
  const [showOnDemand, setShowOnDemand] = useState(false)
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const { useWrapperListstate , useWrapperListDispatch } = useWrapperList();
  const { entitlement } = useWrapperListstate();
  const { resetFetchClubLiveConnect } = useWrapperListDispatch();

  useEffect(() => {
    if(pathname&&(pathname.includes('schedule')||pathname.toLowerCase().includes('clublist'))){
      setShowOnDemand(false)
    }
    else{
      setShowOnDemand(true)
    }
    //type === "classes" ? setShowOnDemand(true) :       setShowOnDemand(false)
  }, [pathname]);

  return (
    <Fragment>
      {entitlement && <div className={!entitlement?.live && entitlement?.ondemand?"":"page-container align-left topProfileLink"}>
        <Grid container justifyContent="flex-start" alignItems="flex-start">
          {entitlement?.live ? <Grid item className='topProfileLink-margin' data-testid="connectLive">
            <a
              onClick={() => {
                navigate(Routes.connectLiveSchedule)
                resetFetchClubLiveConnect()
              }}
              className = {`${!showOnDemand ? `${config.name} dynamiclinks makeTextClickable` : "makeTextClickable"}`}
            >
              <Typography variant="body1">
                {Translate({ id: "connect.LiveScheduleSubNavText" })}
              </Typography>
            </a>
          </Grid> : null}
          {entitlement?.live && entitlement?.ondemand ? <Grid item>
            <a
            data-testid="ondemandLive"
              onClick={() => {
                // dispatch(saveSelectedDate({}))
                navigate( Routes.connectOndemandClasses);
              }}
              className={`${showOnDemand ? `${config.name} dynamiclinks makeTextClickable` : "makeTextClickable"}`}>
              <Typography variant="body1">
                {Translate({ id: "connect.OnDemandSubNavText" })}
              </Typography>
            </a>
          </Grid> : null }
        </Grid>
      </div>}
      {entitlement?.live ? <Livestreamlist showOnDemand={showOnDemand} /> : null}
      {!entitlement?.live && entitlement?.ondemand ? <ClubList /> : null }
    </Fragment>
  )
}
export default WrapperList;



