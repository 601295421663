import React, { createContext, useContext, useState } from "react";
import {SwipeableDrawer} from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import "../components/Drawers/Drawer.scss"


const DrawerContext = createContext();

const Drawer = (props) => {
    const {children} = props
    const [open, setOpen] = useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };   
return (
    <DrawerContext.Provider value={{ handleDrawerOpen, handleDrawerClose }}>
    <>
    <Menu data-testid="hamburger-menu" htmlColor="white" fontSize='large' onClick = {handleDrawerOpen} className="makeTextClickable menu-icon" />
    <SwipeableDrawer
    anchor="left"
    open={open}
    onClose={handleDrawerClose}
    onOpen={handleDrawerOpen}
    >
    {children}
    </SwipeableDrawer>
    </>
    </DrawerContext.Provider>
)}

// A custom hook to use the functions in child components
export const useDrawer = () => useContext(DrawerContext);

export default Drawer