import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import config from "../../assets/config.json"
import { VolumeDown, VolumeUp } from '@material-ui/icons';
import { playerVolume } from 'utils/utilityfunctions';


const useStyles = makeStyles((theme) => ({
  root: {
    width: 300 + theme.spacing(3) * 2,
    marginBottom: "16px",
    [theme.breakpoints.down(414)]: {
      width: "95%" ,
    },
  },
  margin: {
    height: theme.spacing(3),
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
}));

const VolumeSlider = withStyles({
  root: {
    color: config.primaryColor,
    height: 6,
    margin: "0 8px",
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  track: {
    height: 6,
    borderRadius: 4,
  },
  rail: {
    height: 6,
    borderRadius: 4,
    color: "#e0e0de",
  },
})(Slider);


export default function CustomizedSlider(props) {
  const classes = useStyles();

  const id = props.jwplayerId;

  const handleChange = (event,value) => {
    id === "audio-player"
      ? jwplayer(id).setVolume(value / playerVolume.multiplier)
      : jwplayer(id).setVolume(value); 
  }

  return (
    <div className={`${classes.root} ${classes.flex}`}>
      <VolumeDown htmlColor="black" fontSize="large" />
      <VolumeSlider
        valueLabelDisplay="off"
        aria-label="volume slider"
        defaultValue={playerVolume.defaultLevel}
        onChange={handleChange}
      />
      <VolumeUp htmlColor="black" fontSize="large" />
    </div>
  );
}
