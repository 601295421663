import React, { Component, Fragment } from "react";
import "./ClasslistView.css";
import { MdNewReleases } from "react-icons/lib/md";
import "theme/styles/react-select.min.css";
import { Translate } from "utils/Translate";
import config from "assets/config";
import ChannelHome from "containers/Channels/ChannelsHome";
import ConnectHome from "containers/LiveConnect/ClubPage";
import LiveStreamingHome from "containers/LiveStreaming/LiveStreamHome";
import logger from "utils/logger";
import ClassOfTheDay from "components/PlayerAndCarousel/ClassOfTheDay/ClassOfTheDay";
import BrowseClasses from "containers/BrowseClasses/BrowseClasses";
import CollectionClasses from "containers/collections/CollectionClasses";
import LiveScheduleShimmer from "components/Shimmer/LiveScheduleShimmer";
import ClassOfTheDayShimmer from "components/Shimmer/ClassOfTheDayShimmer";
import BrowseClassesShimmer from "components/Shimmer/BrowseClassesShimmer";
import CollectionClassesShimmer from "components/Shimmer/CollectionClassesShimmer";
import ChannelsShimmer from "components/Shimmer/ChannelsShimmer";
import MySubscription from "components/MySubscription/MySubscription";
import { checkValueNotNullNotUndefined } from "utils/utilityfunctions";
import { useSelector } from "react-redux";
import Classes from "components/cardSection/homepage";
class ClasslistView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      collection: [],
      clubClasses: [],
      recordType: "",
      channels: [],
      cotd: [],
      playlist:[],
      fav: [],
      rec: []
    };
    this.collectionNameArray = [];
  }

  componentDidMount() {
    this.props.resetBrowseClassData();
    this.props.setClassesSearchData();
    this.props.setPaginatedData();
    this.props.resetFilters();
    const homepageEntitlement = this.props.isAuthenticated ? this.props?.entitlementLoggedIn : this.props?.entitlementLoggedOut;
    if (homepageEntitlement?.live) {
      this.props.FetchLSConnectHome();
    }
    !homepageEntitlement?.live &&
      homepageEntitlement?.ondemand &&
      this.props.fetchClubLiveConnectClasses();
    this.props.clearClassData();
    this.setState({ loading: true });
    var userId = localStorage.getItem("userId");
    const clientId = localStorage.getItem("clientId");
    const itemCount = 5;
    if (checkValueNotNullNotUndefined(clientId)) {
      const data = {
        tenantId: clientId,
        pageNo: 0,
        pageSize: 0,
        collectionType: "featured",
        ItemCount: itemCount,
      };
      homepageEntitlement?.collection && this.props.collectionListClasses(data);
    }
    if (checkValueNotNullNotUndefined(userId)) {
      logger(this.props.location);
      this.setState({
        showChannelData: true,
      });
      // this.props.onOnDemandClassesLoad(userId);
      if (homepageEntitlement?.cotd) this.props.onCotdHomePageData(userId);
    } else {
      this.setState({
        showChannelData: false,
      });
      //this.props.onOnDemandClassesLoad("");
      if (homepageEntitlement?.cotd) this.props.onCotdHomePageData("");
    }

    this.setState({ classes: [] });

    if (homepageEntitlement?.channel) {
      this.props.fetchAllChannels();
    }
    if (homepageEntitlement?.mysubscription) this.props.getmysubscription(config?.numberOfMySubscriptionItemsOnHomePage);

    if (homepageEntitlement?.playlist) this.props.fetchPlayList(config.name)
    if (homepageEntitlement?.recentactivity) this.props.fetchRecentActivityClasses()
    if (homepageEntitlement?.favourite) this.props.fetchFavouritedClasses()

  }
  componentWillUnmount() {
    this.props.resetFllOndemand();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.clubClasses !== this.props.clubClasses) {
      this.setState({
        clubClasses: this.props.clubClasses?.data,
        recordType: this.props.clubClasses?.recordType,
      });
    }
  
    if (prevProps.fllOnDemand !== this.props.fllOnDemand) {
      const homepageEntitlement = this.props.isAuthenticated ? this.props?.entitlementLoggedIn : this.props?.entitlementLoggedOut;
      if (this.props.fllOnDemand && !homepageEntitlement?.live) {
        this.setState({
          clubClasses: this.props.fllOnDemand.items,
          recordType: "Connect",
        });
      }
    }

    if (
      prevProps.collection !== this.props.collection &&
      this.props.collection !== null
    ) {
      this.setState({ collection: this.props.collection });
    }
    if (
      prevProps.cotdHomePageData !== this.props.cotdHomePageData &&
      this.props.cotdHomePageData !== null
    ) {
      this.setState({ cotd: this.props.cotdHomePageData });
    }

    /// observer channels changes
    if (prevProps.allChannels !== this.props.allChannels) {
      this.updateChannelsState();
    }
    if(prevProps.isAuthenticated !== this.props.isAuthenticated)
    {
    var userId = localStorage.getItem("userId");
    const homepageEntitlement = this.props.isAuthenticated ? this.props?.entitlementLoggedIn : this.props?.entitlementLoggedOut;
    if (homepageEntitlement?.live) this.props.FetchLSConnectHome();
    if (homepageEntitlement?.mysubscription) this.props.getmysubscription(config?.numberOfMySubscriptionItemsOnHomePage);
    if (homepageEntitlement?.playlist) this.props.fetchPlayList(config.name)
    if (homepageEntitlement?.recentactivity) this.props.fetchRecentActivityClasses()
    if (homepageEntitlement?.favourite) this.props.fetchFavouritedClasses()  
    if (homepageEntitlement?.cotd) this.props.onCotdHomePageData(userId);
    if (homepageEntitlement?.channel) this.props.fetchAllChannels();
  }
 
    if (prevProps.playlistData !== this.props.playlistData) {
    this.setState({ playlist: this.props.playlistData})
    }
    if (prevProps.favoriteClasses !== this.props.favoriteClasses) {
      this.setState({ fav: this.props.favoriteClasses })
    }
    if (prevProps.recentActivities !== this.props.recentActivities) {
      this.setState({ rec: this.props.recentActivities })
    }
      // const playlist = this.props.playlistData
      // const fav = this.props.favoriteClasses
      // const rec = this.props.recentActivities
    // }
  }

  updateChannelsState = () => {
    if (this.props.allChannels && this.props.allChannels.length > 0) {
      /// get top 5 channels
      var slicedArray = this.props.allChannels.slice(0, 5);

      this.setState({ channels: slicedArray });
    }
  };

  browseCollectionClick = () => {
    this.GetCollectionTags(this.props.collection);
    this.props.navigate("/collections", {
      state: { collectionArray: this.collectionNameArray },
    });
  };
  GetCollectionTags(itemCollection) {
    if (itemCollection !== null || itemCollection.length !== 0) {
      let lookup = {};
      for (var item, i = 0; (item = itemCollection[i++]); ) {
        var name = item.collectionName;
        if (!(name in lookup)) {
          lookup[name] = 1;
          this.collectionNameArray.push(name);
        }
      }
    }
  }

  render() {
    /* Server error */
// eslint-disable-next-line no-unused-vars
    let homepageEntitlement = this.props.isAuthenticated ? this.props?.entitlementLoggedIn : this.props?.entitlementLoggedOut
    let featuresConfig = this.props.featuresConfig
    let classes;
    if (this.state?.collection?.length === 0 && !this.state?.loading) {
      classes = (
        <div className="flex" style={{ marginTop: "20px" }}>
          <MdNewReleases
            style={{
              width: "60px",
              height: "60px",
              margin: "0 10px",
              display: "none",
            }}
          />
          <h4>{Translate({ id: "ClassListViews.NoClassesFound" })}</h4>
        </div>
      );
    }
    var collectionArray = this.state.collection;
    var channelData = this.state.channels;
    var cotdObj = this.state.cotd;
    var rec = this.state.rec;
    var fav = this.state.fav?.data;
    var playlist = this.state.playlist.list?.data;

    return (
      <Fragment>
        <div className="page-container">
          {featuresConfig?.subscription && homepageEntitlement?.mysubscription ? (this.props.mySubscriptionLoading ? <CollectionClassesShimmer /> : <MySubscription /> ): null}
          {homepageEntitlement?.live ?
            (this.props.liveConnectLoading ? <div className='m-t-30'><LiveScheduleShimmer /></div> :
              this.state.clubClasses && this.state.clubClasses.length > 0 ?
                homepageEntitlement?.live ?
                  this.state.recordType === "Live" ? <LiveStreamingHome clubClasses={this.state.clubClasses} /> :
                    this.state.recordType === "Connect" ? <ConnectHome clubClasses={this.state.clubClasses} /> : null
                  : homepageEntitlement?.ondemand ? <ConnectHome clubClasses={this.state.clubClasses} /> : null
                : null) :null
          }
          {!homepageEntitlement?.live && homepageEntitlement?.ondemand ? <ConnectHome clubClasses={this.state.clubClasses} /> :null}
          {homepageEntitlement?.cotd ? (this.props.cotdLoading ? <ClassOfTheDayShimmer /> : <ClassOfTheDay cotdObj={cotdObj} />) : null}
          {homepageEntitlement?.category ? ((this.props.isMetadataLoading || this.props.fetchUserDetailsLoading) ? <BrowseClassesShimmer /> : <BrowseClasses showArrow={true} />) : null}
          {homepageEntitlement?.channel ? ((this.props.isAllChannelsLoading || this.props.fetchUserDetailsLoading) ? <ChannelsShimmer /> : channelData && channelData.length > 0 && <ChannelHome channelData={channelData} />) : null}
          {homepageEntitlement?.collection ? ((this.props.loading || this.props.fetchUserDetailsLoading) ? <CollectionClassesShimmer /> : <CollectionClasses collectionArray={collectionArray} />): null}
          {homepageEntitlement?.favourite ? ((this.props.loading || this.props.fetchUserDetailsLoading) ? <CollectionClassesShimmer /> : <Classes collectionArray={fav} section={"fav"} />): null}
          {homepageEntitlement?.playlist ? ((this.props.loading || this.props.fetchUserDetailsLoading) ? <CollectionClassesShimmer /> : <Classes collectionArray={playlist} section={"playlist"} />): null}
          {homepageEntitlement?.recentactivity ? ((this.props.loading || this.props.fetchUserDetailsLoading) ? <CollectionClassesShimmer /> : <Classes collectionArray={rec} section={"res"} />): null}
        </div>
      </Fragment>
    );
  }
}

export default ClasslistView;
