/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFitRadioList,
  getFitRadioMix,
  resetFitRadioData
} from "../../store/actions/fitRadio";
import {
  setSkipTimer,
  setSkipCount,
} from "../../store/actions/fitRadioRoyalty";
import { Notification } from "../../containers/PlayerAndCorousel/views/Player/PlayList/Notification";
import {
  getTranslatedFile,
  getDeviceName,
  getLocalRegion,
  getOsName,
} from "../../utils/utilityfunctions";
import FitRadioRoyaltyCacheManager from "./FitRadioRoyaltyCacheManager";
import moment from "moment";
import FitRadioMix from "./FitRadioMix";
import FitRadioList from "./FitRadioList";
import VolumeSlider from "components/Sliders/VolumeSlider";
import CloseMobile from "CommonImages/closeMobile";
import { NavigateBefore, NavigateNext } from "@material-ui/icons";
import IconMusicLib from "CommonImages/icon-music-lib";
import IconValidation from "CommonImages/validationCheck";
import { Button } from "@material-ui/core";
import { Translate } from "utils/Translate";


const config = require('../../assets/config.json')
require("./FitRadio.css");

const translate = getTranslatedFile();
const FitRadio = (props) => {
  const dispatch = useDispatch();
  const fitRadioListData = useSelector((state) => state.fitRadio.list);
  let skipsLimitSet = useSelector(
    (state) => state.fitRadioRoyalty?.skipsLimitSet
  );
  let skipCounter = useSelector((state) => state.fitRadioRoyalty?.skipCounter);

   const lastPlayedMixIds = useSelector(
     (state) => state.fitRadio.lastPlayedMixIds
   );
  const fitRadioMixData = useSelector(
    (state) => state.fitRadio.fitRadioMixData
  );
  const [showToast, setShowToast] = useState(false);
  const [showBox, setShowBox] = useState(false)

  const carouselRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTrackPlaying, setIsTrackPlaying] = useState(false);
  const [currentPlayingTrack, setCurrentPlayingTrack] = useState(null);
  const [trackPlayedData, setTrackPlayedData] = useState({
    startedTime: null,
    skipCount: 0,
  });
  const [selectedList,setSelectedList] = useState(null)
  let trackPlayedTimeoutRef = useRef({ timeout: null, isRemoved: true });
  const FIT_RADIO_MAX_SKIP_COUNT = 6;
  let isVideoPlaying = props.isVideoPlaying
  const [showToastSkipLimit, setShowToastSkipLimit] = useState(false);
  useEffect(() => {
    dispatch(getFitRadioList(config["tenantID"]));
    return ()=>{
      dispatch(resetFitRadioData())
    }
  }, []);

  const setupTrackPlayer = async (mixData) => {
    // TODO
   // jwplayer("audio-player").stop();
    //  SoundPlayer.loadUrl(mixData.url);
    //  SoundPlayer.playUrl(mixData.url);
    if (mixData.tracks && mixData.tracks.length > 0) {
      setCurrentPlayingTrack(mixData.tracks[0]);
    }
    // if (isVideoPlaying) {
    //   playTrack();
    // } else {
    //   pauseTrack();
    // }
  };
  
 useEffect(() => {
   if (fitRadioMixData) {
     removeTrackPlayTimeout();
     if (fitRadioMixData.tracks && fitRadioMixData.tracks.length > 0) {
       setCurrentPlayingTrack(fitRadioMixData.tracks[0]);
     }
     setupTrackPlayer(fitRadioMixData);
   } else {
     resetTrackPlayer();
   }
 }, [fitRadioMixData]);

   const resetTrackPlayer = () => {
     //jwplayer("audio-player").stop();
     setCurrentPlayingTrack(null);
     removeTrackPlayTimeout();
   };
  const timeStringToSeconds = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  /*
   * This method is used to reset the timeout for currently played track
   */
  const updateTrackPlayTimeout = () => {
    const currentTrackPositionInSeconds = jwplayer("audio-player").getPosition();
    const { currentTrack, nextTrack } = getCurrentAndNextTracks();
    if (!currentTrack) {
      return;
    }
    const timeOffsetForTimeout = nextTrack
      ? nextTrack.time
      : fitRadioMixData?.length;
    trackPlayedTimeoutRef.current.timeout = setTimeout(
      () => {
        if (currentTrack?.id !== nextTrack?.id) callRoyaltyApi(currentTrack);
        if (nextTrack) {
          setCurrentPlayingTrack(nextTrack);
          updateTrackPlayTimeout();
        }
      },
      timeOffsetForTimeout
        ? (timeStringToSeconds(timeOffsetForTimeout) -
            currentTrackPositionInSeconds) *
            1000
        : 100
    );
     trackPlayedTimeoutRef.current.isRemoved = false;
  };
  /*
   * This method is used to get the currently playing and next tracks
   */
  const getCurrentAndNextTracks = () => {
    const currentTrackPositionInSeconds = jwplayer("audio-player").getPosition();
    let currentTrack;
    const nextTrack = fitRadioMixData?.tracks?.find((track) => {
      const trackStartTimeInSeconds = timeStringToSeconds(track.time);
      if (currentTrackPositionInSeconds >= trackStartTimeInSeconds) {
        currentTrack = track;
        return false;
      }
      return true;
    });
    return {
      currentTrack,
      nextTrack,
    };
  };
  /*
   * This method is used to call the royalty api
   * to post the played track progress
   */

  const callRoyaltyApi = (currentTrack) => {
    const currentTrackPositionInSeconds = jwplayer("audio-player").getPosition();
    FitRadioRoyaltyCacheManager.getInstance().execute({
      tenantId: config["tenantID"],
      trackId: currentTrack.id,
      playedat: moment(
        new Date(
          Date.now() -
            (currentTrackPositionInSeconds -
              timeStringToSeconds(currentTrack.time)) *
              1000
        )
      ).unix(),
      sourcedetail: fitRadioMixData?.title ?? "",
      sourcestream: fitRadioMixData?.url ?? "",
      os: getOsName(),
      device: getDeviceName(),
      vendor: 299,
      country: getLocalRegion() ?? "India",
    });
  };
  const tapFitRadioListCard = (listId) => {
    if (listId) {
      setSelectedList(listId)
      const element = document.getElementById(listId)
      const carousalWidth = document.getElementsByClassName("fit-radio-card-carousel")[0].clientWidth
      const rect = element?.getBoundingClientRect()
      const carousalStartX = screenWidth > 1359 ? 80 : 32
      if ((rect.left - carousalStartX) < 0){
        scrollItems("previous")
      } else if ((rect.right - carousalStartX) > carousalWidth) {
        scrollItems("next")
      }
      dispatch(getFitRadioMix(listId,  lastPlayedMixIds, config["tenantID"]));
    }
  };

  const showToastFunction = () => {
    if (isVideoPlaying !== "playing") {
      setShowToast(true);
    }
  };
   const callFitRadioMixApi = () => {
     if (fitRadioMixData && fitRadioMixData.listId) {
       dispatch(
         getFitRadioMix(
           fitRadioMixData.listId,
           lastPlayedMixIds,
           config["tenantID"]
         )
       );
     }
   };
  var screenWidth = window.innerWidth
  var itemsPerPage = screenWidth > 1359 ? 3 : 2 // Adjust based on the number of items to display
  const scrollItems = (direction) => {
    if (carouselRef.current) {
      const items = carouselRef.current.getElementsByClassName("grid-item");
      if (items.length > 0) {
        const totalItems = items.length;
        const newIndex =
          direction === "next"
            ? Math.min(currentIndex + itemsPerPage, totalItems - itemsPerPage)
            : Math.max(currentIndex - itemsPerPage, 0);
        setCurrentIndex(newIndex);
        carouselRef.current.scrollLeft = newIndex * items[0].offsetWidth;
      }
    }
  };

  /*
   * This method is used to skip the current and play the
   * next track
   */
 
  const onNextTrackClick = async () => {
    setShowToastSkipLimit(false);
    const currentTrackPositionInSeconds = jwplayer("audio-player").getPosition();
    if (!isTrackPlaying) {
      // TODO :: SKIP BUTTON DISABLE MESSAGE
      return;
    }
    if (skipCounter >= FIT_RADIO_MAX_SKIP_COUNT) {
      // TODO :: SKIP BUTTON DISABLE MESSAGE
      setShowToastSkipLimit(true);
    } else {
      if (skipsLimitSet === null) {
        dispatch(setSkipTimer(true))
        setTimeout(() => {
          dispatch(setSkipTimer(null),
            dispatch(setSkipCount(0))
          )
        }, 3600000);
      }
      dispatch(setSkipCount(skipCounter+1))
      removeTrackPlayTimeout();
      let currentTrack = null;
      const nextTrack = fitRadioMixData?.tracks?.find((track) => {
        const trackStartTimeInSeconds = timeStringToSeconds(track.time);
        if (currentTrackPositionInSeconds > trackStartTimeInSeconds) {
          currentTrack = track;
          return false;
        }
        return true;
      });
      if (nextTrack) {
        setCurrentPlayingTrack(nextTrack);
        await jwplayer("audio-player").seek(timeStringToSeconds(nextTrack.time));
        updateTrackPlayTimeout();
      } else {
        pauseTrack();
        callFitRadioMixApi();
      }

      // TODO :: Reset the track played data if required ?? do we need it now?
      if (
        trackPlayedData.startedTime === null ||
        moment(new Date()).diff(moment(trackPlayedData.startedTime), "hours") >=
          1
      ) {
        setTrackPlayedData({
          startedTime: new Date(),
          skipCount: 1,
        });
      } else if (trackPlayedData.skipCount < FIT_RADIO_MAX_SKIP_COUNT) {
        setTrackPlayedData({
          ...trackPlayedData,
          skipCount: trackPlayedData.skipCount + 1,
        });
      }
      // Call royalty api
      if (currentTrack) {
        const playedTime =
          currentTrackPositionInSeconds -
          timeStringToSeconds((currentTrack).time)
        if (playedTime > 30) {
          callRoyaltyApi(currentTrack)
        }
      }
    }
  };


    const playTrack = () => {
      if(isVideoPlaying === "playing"){
        if (skipsLimitSet === null) {
          dispatch(setSkipTimer(true))
          setTimeout(() => {
            dispatch(setSkipTimer(null),
            dispatch(setSkipCount(0))
            )
          }, 3600000);
        }
        jwplayer("audio-player").play();
        if (trackPlayedTimeoutRef.current.isRemoved) {
          updateTrackPlayTimeout();
        }
        setIsTrackPlaying(true);
      }else{
        showToastFunction()
      }
    };

    const pauseTrack = () => {
        jwplayer("audio-player").pause();
        removeTrackPlayTimeout();
        setIsTrackPlaying(false);
    };
  /*
   * This method is used to remove the timeout for currently played track
   */
  const removeTrackPlayTimeout = () => {
    if (
      trackPlayedTimeoutRef.current?.timeout &&
      !trackPlayedTimeoutRef.current?.isRemoved
    ) {
     clearTimeout(trackPlayedTimeoutRef.current?.timeout);
      trackPlayedTimeoutRef.current.isRemoved = true;
    }
  };

  const toggleBox = () => {
    setShowBox(!showBox) 
  }
  return (
    <div class="fit-radio-container" data-testid="fit-radio">
      {fitRadioMixData && fitRadioMixData != "" ? (
        <FitRadioMix
          ref={trackPlayedTimeoutRef}
          isTrackPlaying={isTrackPlaying}
          isTrackDisabled={!isVideoPlaying}
          isNextDisabled={
            !isTrackPlaying || skipCounter >= FIT_RADIO_MAX_SKIP_COUNT
          }
          currentTrackTitle={currentPlayingTrack?.title ?? ""}
          currentTrackArtist={currentPlayingTrack?.artist ?? ""}
          onNextClick={onNextTrackClick}
          fitRadioMixData={fitRadioMixData}
          isVideoPlaying={isVideoPlaying}
          playTrack={playTrack}
          pauseTrack={pauseTrack}
          removeTrackPlayTimeout={removeTrackPlayTimeout}
          setIsTrackPlaying={setIsTrackPlaying}
          toggleBox = {toggleBox}
        />
      ) : (
        <div className="mainTitle">
          <span className="Rectangle">
            <IconMusicLib cls={"music-icon"} />
          </span>
            <span className="mix-box makeTextClickable" onClick={toggleBox}>
              {Translate({ id:"FitRadio.PickYourWorkoutMusic"})}
          </span>
          <CloseMobile
            cls={showBox ? "cross-icon makeTextClickable transition-height" : "cross-icon hidden transition-height"}
            color={config.primaryColor}
            onClick={toggleBox}
          />
        </div>
      )}
      <div className={showBox ? "fit-radio-card-carousel-container transition-height" : "fit-radio-card-carousel-container hidden transition-height"}>
        <NavigateBefore
          className={
            currentIndex !== 0
              ? "buttonShiftLeft makeTextClickable"
              : "hidden-arrows buttonShiftLeft makeTextClickable"
          }
          onClick={() => scrollItems("previous")}
          color="secondary"
          fontSize="large"
        />
        <div class={showBox ? "fit-radio-card-carousel transition-height" : "fit-radio-card-carousel hidden transition-height"} ref={carouselRef} data-testid="fit-radio-carousel">
          <div class={showBox ? "fit-radio-card grid-container transition-height" : "fit-radio-card grid-container hidden transition-height"}>
            {fitRadioListData &&
              fitRadioListData.map((list, index) => (
                <div
                  id={list.listId}
                  key={list.listId}
                  class={showBox ? "grid-item makeTextClickable transition-height" : "grid-item hidden transition-height"}
                  onClick={() => tapFitRadioListCard(list.listId)}
                >
                  {selectedList === list.listId && (
                    <IconValidation
                      cls={"icon-selected"}
                      color={config.primaryColor}
                    />
                  )}
                  <img
                    src={list.thumbnailUrl}
                    alt={index}
                    class="fit-radio-card-thumb-image"
                  />
                  <span class="list-title">{list.title}</span>
                </div>
              ))}
          </div>
        </div>
        <NavigateNext
          className={
            currentIndex < fitRadioListData.length - itemsPerPage
              ? "buttonShiftRight makeTextClickable"
              : "hidden-arrows buttonShiftRight makeTextClickable"
          }
          color="secondary"
          onClick={() => scrollItems("next")}
          fontSize="large"
        />
      </div>
      <div className={showBox ? "slider-container transition-height" : "slider-container transition-height hidden"}>
        <div className="slider-heading"> {Translate({ id:"FitRadio.MusicVolume"})} </div>
        <VolumeSlider jwplayerId={"audio-player"} />
        <div className="slider-heading"> {Translate({ id: "FitRadio.InstructorVolume" })} </div>
        <VolumeSlider jwplayerId={props.classId} />
      </div>
      {fitRadioMixData && fitRadioMixData != "" && <Button
        id="toggle-button"
        variant="contained"
        color="secondary"
        className={showBox ? "button btn-default toggle-button transition-height" : "button btn-default toggle-button hidden transition-height"}
        onClick={toggleBox}
      >
        {Translate({id: "Player.Done", defaultMessage: "Done"})}
      </Button> 
      }
      {showToast && (
        <Notification
          {...{
            severity: "success",
            text:
              "Music will play once the workout starts" ??
              translate?.FitRadio?.MusicWillPlayOnceTheWorkoutStarts,
            show: "open",
          }}
        />
      )}
      {showToastSkipLimit && (
        <Notification
          {...{
            severity: "success",
            text:
              "Skip limit is reached." ??
              translate?.FitRadio?.SkipLimitIsReached,
            show:"open",
          }}
        />
      )}
    </div>
  );
};

export default FitRadio;