import React, { useCallback } from 'react';
import "../FitRadio/FitRadio.css"
const AudioPlayer = props => {
    const {
        hideControls,
        file,
        autostart,
        initPlayer,
        image,
        eventId,
        hideFullScreen,
        playList,
        id
    } = props;
    const JP = window.jwplayer;
    // const {getVideoDetails} =  useInternetSpeedMeter({imageUrl:image,eventId});
    let player = null;
    
    const loopEnabled = localStorage.getItem("isLoop") !== null ? JSON.parse(localStorage.getItem("isLoop")) : false;
    const playerInit = useCallback(() => {
        try {
            if (JP && player === null) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                player = JP("audio-player").setup({
                    autostart: autostart,
                    file: file,
                    events: {
                        seeked: () => {
                            console.log("SEEKED");
                        },
                        ready: () => {
                            hideControls && (useHandelSeekBar.Video = null);
                            handelRightClick(player);
                            hideControls && handelMenuBtns(player);
                        },
                        beforePlay: () => {
                            handelRightClick(player);
                        },
                        play: () => {
                            hideControls && handelMenuBtns(player);
                            hideFullScreen && handelMenuBtnFullScreen(player);
                            if (player.getState() === "PLAYING") {
                                player.hideControls();
                            }
                            if (
                                hideControls &&
                                useHandelSeekBar.isIos &&
                                useHandelSeekBar.Video === null
                            )
                                useHandelSeekBar.handelEvents(player.getContainer());
                            // getVideoDetails(player)
                        },
                        displayClick: function () {
                            // if(hideControls){
                            //     setTimeout(()=>{
                            //     this.play()
                            //     },150)
                            // }
                        },
                        pause: () => {
                            // player.setControls(false)
                        },
                    },
                    ...(loopEnabled && { repeat: loopEnabled }),
                });
                return player;
            }
            else {
                return null;
            }
        } catch (error) {
            console.log(error.message);
            console.log(error.name);
            return null;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JP, initPlayer]);

    const handelRightClick = (Player) => {
        const videoEle = Player.getContainer();
        videoEle.addEventListener("contextmenu", () => {
            const menuEle = videoEle.querySelectorAll("ul.jw-rightclick-list.jw-reset")[0];
            menuEle.style.display = "none";
            return false;
        });
        // videoEle.querySelectorAll("ul.jw-rightclick-list.jw-reset>li:first-child")[0].style.display="none";
    }
    const handelMenuBtnFullScreen = (Player) => {
        try {
            const videoEle = Player.getContainer();
            const fullscreen = videoEle.querySelectorAll(".jw-icon-fullscreen");
            if (videoEle && fullscreen[1]) {
                fullscreen[1].style.display = "none";
            }
        } catch (error) {
            console.log(error.name);
            console.log(error.message);
        }
    }
    const handelMenuBtns = (Player) => {
        try {
            const videoEle = Player.getContainer();
            const rewind = videoEle.querySelectorAll(".jw-icon-rewind");
            const slider = videoEle.querySelectorAll(".jw-slider-horizontal");
            const duration = videoEle.querySelectorAll(".jw-text-duration");
            const liveBtn = videoEle.querySelectorAll(".jw-text-live");
            if (videoEle && rewind[1]) {
                // rewind[1].style.display="none";
                console.log("CALLED");
            }
            if (videoEle && slider[0]) {
                slider[0].style.pointerEvents = "none";
            }
            if (videoEle && duration[0]) {
                duration[0].style.display = "none";
            }
            if (videoEle && liveBtn[0]) {
                liveBtn[0].style.display = "flex";
            }
        } catch (error) {
            console.log(error.name);
            console.log(error.message);
        }
    }
    const setUpVid = (video) => {
        const Jplayer = playerInit();
        if (Jplayer)
            Jplayer.on({
                'ready': () => {
                    console.log("here");
                    props.startVideo(video, Jplayer)
                },

            });
    }
    return (
        <div ref={(video => setUpVid(video))} id={"audio-player"} >
            <img alt="video" src={`${props.image}`}/>
        </div>
    )
}

export { AudioPlayer }