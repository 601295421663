import React from "react";
import PropTypes from "prop-types";
 require("./FitRadio.css");

const FitRadioList = (props) => {
  const { title, thumbnailUrl } = props;
  return (
    <div>
      {/* <div class="fit-radio-card" key={index}>
        <div class="fit-radio-card-thumb-image">
          <img src={thumbnailUrl} alt={index} />
          <span class="list-title">{title}</span>
        </div>
      </div> */}
    </div>
  );
};

FitRadioList.propTypes = {};

export default { FitRadioList };
