import { useDispatch, useSelector } from "react-redux";
import { searchOnDemandClass, sendCategoryDetailsToLocalytics } from '../store/actions/ondemand';
import { resetFilters, setFilteredClassSearchData, setKeywords, setselectedFilters, setFilteredTopKeywords, setPageCount, setPaginatedData, setClassesSearchData, settempClassSearchData, setTopKeywords, setCategories } from '../store/actions/filter';


  const useCategories = () => {
      const dispatch = useDispatch();
      const useCategoriesState = () => ({
         metadata : useSelector(state => state.onDemand.Metadata),
         selectedFilters : useSelector(state => state.filter.selectedFilters),
         tempFilterSearchClasses : useSelector(state => state.onDemand.tempFilterSearchClasses),
         loading : useSelector(state => state.onDemand.searchLoading),
         searchTextLoading: useSelector(state => state.onDemand.searchTextLoading),
         filteredClassSearchData : useSelector(state => state.filter.filteredClassSearchData),
         filteredSkill : useSelector(state => state.filter.filteredSkill),
         filteredDuration : useSelector(state => state.filter.filteredDuration),
         filteredKeywords : useSelector(state => state.filter.filteredKeywords),
         filteredSubCategory : useSelector(state => state.filter.filteredSubCategory),
         filteredFocusArea : useSelector(state => state.filter.filteredFocusArea),
         filteredEquipments : useSelector(state => state.filter.filteredEquipments),
         filterSortBy : useSelector(state => state.filter.filterSortBy),
         filterSearchText : useSelector(state => state.filter.filterSearchText),
         filteredIntensity : useSelector(state => state.filter.filteredIntensity),
         filteredLanguage : useSelector(state => state.filter.filteredLanguage),
         filteredTopKeywords : useSelector(state => state.filter.filteredTopKeywords),
         pageCount : useSelector(state => state.filter.pageCount),
         paginatedData : useSelector(state => state.filter.paginatedData),
         classesSearchData : useSelector(state => state.filter.classesSearchData),
         tempClassSearchData :useSelector(state => state.filter.tempClassSearchData),
         topKeywords : useSelector(state => state.filter.topKeywords),
         searchedOnDemandClassesObj : useSelector(state => state.onDemand.searchedOnDemandClasses),
         // eslint-disable-next-line eqeqeq
         isAuthenticated : useSelector(state => state.auth.token != "")
    })
     const useCategoriesDispatch = () => ({
        sendCategoryDetailsToLocalytics: (categoryName, categoryId) => {
            dispatch(sendCategoryDetailsToLocalytics({ name: categoryName, categoryId }))
        }, 
        searchOnDemandClass: (type, keyword) => {
            dispatch(searchOnDemandClass(type, keyword, false, 1, 0, 9999));
        }, 
        setPageCount: (page) => {
            dispatch(setPageCount(page))
        },
        setPaginatedData : (data) => {
            dispatch(setPaginatedData(data))
        },
        setTempClassSearchData : (data) => {
            dispatch(settempClassSearchData(data))
        },
        setFilteredClassSearchData : (data) => {
            dispatch(setFilteredClassSearchData(data))
        },
        setFilteredTopKeywords : (sortedTopKeywords) => {
            dispatch(setFilteredTopKeywords(sortedTopKeywords))
        },
        setClassesSearchData : (items) => {
            dispatch(setClassesSearchData(items))
        },
        setTopKeywords : (metaData, metaDataTopLabels) => {
            dispatch(setTopKeywords(metaData && metaDataTopLabels))
        },
        setKeywords : (filterValue) => {
            dispatch(setKeywords(filterValue));
        },
        setSelectedFilters : (tempFilters) => {
            dispatch(setselectedFilters(tempFilters))
        },
        resetFilters : () => {
            dispatch(resetFilters());
        },
         setSubCategories: (filterValue) => {
             dispatch(setCategories(filterValue))
         },
        searchOnDemandClassWithFilter : (id,filteredKeywords,filteredFocusArea,filteredSkill,filteredEquipments,filteredDuration,filteredIntensity,searchText,filterSortBy,filteredLanguage,filteredSubCategory,fromSearch) => {
            dispatch(searchOnDemandClass(id, filteredKeywords, false, 1, 0, 9999, false, filteredFocusArea.length>0?filteredFocusArea[0].filterValue:"", filteredSkill, filteredEquipments.length>0?filteredEquipments[0].filterValue:"", filteredDuration, filteredIntensity, searchText, filterSortBy, filteredLanguage.length>0?filteredLanguage[0].filterValue:"", filteredSubCategory, fromSearch));
        }
    })
    return {useCategoriesState, useCategoriesDispatch}
}
export {useCategories}