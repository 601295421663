/* eslint jsx-a11y/anchor-is-valid: 0 */
/* eslint eqeqeq: 0 */
import React, { useEffect, useReducer } from 'react'
import { Grid, Typography, Chip, Button, Paper, TextField } from "@material-ui/core";
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Translate } from '../../utils/Translate';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { IntensityLevelText, SkillLevelText, viewAllCategory } from "../../utils/constants";
import { getTranslatedFile, isEmbeddedMode } from "../../utils/utilityfunctions";
import { getDurationInMinsWithLabel, SortValues } from "../../utils/utilityfunctions";
import { useFilterDrawer } from "../../hooks/useFilterDrawer";
import ClearSearch from '../../CommonImages/clear-search';

const config = require("../../assets/config.json")

const AirbnbSlider = withStyles({
  root: {
    color: config.primaryColor,
    height: 3,
    padding: '13px 0',
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    boxShadow: '#ebebeb 0 2px 2px',
    '&:hover, &$active, &:focus': {
      boxShadow: '#ebebeb 0 2px 2px',
      border: '1px solid currentColor',
      backgroundColor: config.primaryColor,
      color: '#fff',
    },
    '&::after': {
      top: '2px',
      left: '3px',
      right: '0',
      bottom: 0,
      content: '"|||"',
      fontSize: '7px'
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 0px)',
    top: -36,
    fontFamily: 'SFProRounded-regular ',
    fontSize: '15px',
    '& *': {
      background: config.primaryColor,
      color: '#fff',
    },
  },
  track: {
    height: 3,
  },
  rail: {
    color: config.primaryColor + '4D',
    opacity: 1,
    height: 3,
  },
})(Slider);


function FilterDrawer(props) {
  const { useFilterDrawerState, useFilterDrawerDispatch , filterDrawerReducer} = useFilterDrawer();
  const { selectedFilters, filteredSkill, 
          filteredDuration, filteredKeywords, filteredSubCategory,
          filteredFocusArea, filteredEquipments, filterSortBy, 
          filterSearchText, filteredIntensity, filteredLanguage, filteredMediaType, metadataEntitlement} = useFilterDrawerState(props);
  const { setOnDemandSelectedFilter, setSelectedFilters, setOnDemandKeywords,
          setKeywords,setOnDemandDuration, setDuration, setOnDemandSkillLevel,
          setSkillLevel ,setOnDemandFocusArea ,setFocusArea ,setOnDemandEquipmentsTypes ,
          setEquipmentsTypes,setOnDemandIntensityLevel,setIntensityLevel,setOnDemandClassLanguage,
          setClassLanguage, setOnDemandCategories, setCategories, setOnDemandSort, setSort,
          setFilterSearchText,setOnDemandFilterSearchText,resetOnDemandFilters,resetFilters,setMediaType,setOnDemandMediaType } = useFilterDrawerDispatch(); 

  const { setReducerData,useFilterDrawerReducer, initialState} = filterDrawerReducer;
  const [filterDrawerState, filterDrawerDispatch] = useReducer(useFilterDrawerReducer, initialState);
  const { clearSearch,skillValue,intensityValue,levelText,level,intensityText,intensity,searchText,keyWordPressed,expanded,isIOS,isAndroid,translatedFile,mediaType } = filterDrawerState;
  

  const handleChange = (panel) => (event, isExpanded) => {
    isExpanded ? setReducerData(filterDrawerDispatch,{ expanded:panel}) : setReducerData(filterDrawerDispatch,{ expanded:false})
  };

  useEffect(() => {
    if (filteredIntensity.length > 0) {
      let tempIntensity = filteredIntensity.split(',')
      setReducerData(filterDrawerDispatch,{intensityValue:[parseInt(tempIntensity[0]), parseInt(tempIntensity[1])]})
    }
    else if (filteredIntensity.length === 0) {
      setReducerData(filterDrawerDispatch,{ intensityValue : [3, 8]})
    }
    if (filteredSkill.length > 0) {
      let tempSkill = filteredSkill.split(',')
      setReducerData(filterDrawerDispatch,{skillValue :[parseInt(tempSkill[0]), parseInt(tempSkill[1])]})
    }
    else if (filteredSkill.length === 0) {
      setReducerData(filterDrawerDispatch,{skillValue :[1,1]})
    }
    if (filterSearchText.length > 0) {
      setReducerData(filterDrawerDispatch,{ searchtext: filterSearchText, clearSearch : 'block',})
    }
     if (filteredMediaType.length > 0) {
       setReducerData(filterDrawerDispatch, {
         mediaType: filteredMediaType,
       });
     }
    window.scrollTo(0, 0);
    // console.log(IntensityValue,"IntensityValue")
    var iOS =!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    setReducerData(filterDrawerDispatch,{ isIOS: (iOS)});
    var android =!!navigator.platform && /Linux|Android|android/.test(navigator.platform);
    setReducerData(filterDrawerDispatch,{isAndroid: android });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (searchText !== undefined && searchText.length === 0)
      props.fetchDataByFilters(selectedFilters)
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  useEffect(() => {
    if (filteredSkill.length === 0 && filteredIntensity.length === 0) {
      setReducerData(filterDrawerDispatch,{skillValue :[1, 10],intensityValue : [1, 10], levelText: "", intensityText: ""})
    window.scrollTo(0, 0);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredSkill, filteredIntensity])

  function handleKeyPress(e) {
    if (clearSearch == "none") {
      setReducerData(filterDrawerDispatch,{ clearSearch : 'block',})
    }
  };

  const handleSkillValueChange = (event, newValue) => {
    setReducerData(filterDrawerDispatch,{skillValue :(newValue)});;
    for (let i = 0; i < newValue.length; i++) {
      let newVal = newValue[i]
      setReducerData(filterDrawerDispatch, { levelText: (SkillLevelText[newVal]) });
    }
    setReducerData(filterDrawerDispatch,{level : (newValue[0] + ',' + newValue[1]) })
  };

  useEffect(() => {
    if (level !== "") {
      fetchData("skill level", level)
    window.scrollTo(0, 0);
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level])

  const handleIntensityValue = (event, iValue) => {
    setReducerData(filterDrawerDispatch,{ intensityValue :(iValue)});
    for (let i = 0; i < iValue.length; i++) {
      let newVal = iValue[i]
      setReducerData(filterDrawerDispatch, { intensityText: (IntensityLevelText[newVal]) });
    }
    setReducerData(filterDrawerDispatch,{ intensity :(iValue[0] + ',' + iValue[1])});
  };

  useEffect(() => {
    if (intensity !== "") {
      fetchData("intensity", intensity)
    window.scrollTo(0, 0);
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intensity])

   useEffect(() => {
     if (mediaType !== "") {
       fetchData("media type", mediaType);
       window.scrollTo(0, 0);
     }
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [mediaType]);

  const fetchData = (filterType, filterValue,filterTag="") => {
    setReducerData(filterDrawerDispatch,{keyWordPressed : true})
    let tempFilters = [...selectedFilters], sameValueSelected = false
    if (selectedFilters.length === 0) {
      tempFilters.push({ filterType, filterValue })
      saveFilterValues(filterType, filterValue,filterTag)
    }
    else {
      for (let index = 0; index < selectedFilters.length; index++) {
        if (selectedFilters[index].filterType === filterType) {
          if (selectedFilters[index].filterValue === filterValue)
            sameValueSelected = true
          tempFilters.splice(index, 1);
        }
      }
      if (!sameValueSelected) {
        tempFilters.push({ filterType, filterValue })
        saveFilterValues(filterType, filterValue,filterTag)
      }
      else {
        saveFilterValues(filterType, '','')
      }
    }
    if(props.showFLLOnDemand)
      setOnDemandSelectedFilter(tempFilters)
    else
      setSelectedFilters(tempFilters)
  }

  useEffect(() => {
    if (keyWordPressed) {
      props.fetchDataByFilters(selectedFilters)
    }
    setReducerData(filterDrawerDispatch,{keyWordPressed : false})
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters])

  const saveFilterValues = (filterType, filterValue,filterTag) => {
    switch (filterType.toString().toLowerCase()) {
      case `keywords`:
        if(props.showFLLOnDemand)
          setOnDemandKeywords(filterValue)
        else
          setKeywords(filterValue)
        break;
      case `duration`:
        if(props.showFLLOnDemand)
          setOnDemandDuration(filterValue)
        else
          setDuration(filterValue)
        break;
      case `skill level`:
        if(props.showFLLOnDemand)
          setOnDemandSkillLevel(filterValue)
        else
          setSkillLevel(filterValue)
        break;
      case `focus area`:
        if(props.showFLLOnDemand)
          setOnDemandFocusArea(filterValue, filterTag)
        else
          setFocusArea(filterValue, filterTag)
        break;
      case `equipment`:
        if(props.showFLLOnDemand)
          setOnDemandEquipmentsTypes(filterValue, filterTag)
        else
          setEquipmentsTypes(filterValue, filterTag)
        break;
      case `intensity`:
        if(props.showFLLOnDemand)
          setOnDemandIntensityLevel(filterValue)
        else
          setIntensityLevel(filterValue)
        break;
      case `class language`:
        if(props.showFLLOnDemand)
          setOnDemandClassLanguage(filterValue, filterTag)
        else
          setClassLanguage(filterValue, filterTag)
        break;
      case `sub category`:
        if(props.showFLLOnDemand)
          setOnDemandCategories(filterValue)
        else
          setCategories(filterValue)
        break;
      case `sort`:
        if(props.showFLLOnDemand)
          setOnDemandSort(filterValue)
        else
          setSort(filterValue)
        break;
      case `media type`:
        if(props.showFLLOnDemand){
          setOnDemandMediaType(filterValue)
        }
        else
          setMediaType(filterValue)
        break;
      default :
         return null
    }
  }

  const searchEnterPressed = (e) => {
      if (e.keyCode === 13 &&searchText !== undefined &&searchText.length > 0) {
        e.target.blur();
        searchData()
      }
  }

  const searchData = () => {
    if(props.showFLLOnDemand)
      setOnDemandFilterSearchText(searchText)
    else
      setFilterSearchText(searchText)
    props.fetchDataByFilterSearch(searchText)
  }

  const viewClasses = () => {
    props.closeHandler()
  }

  const getFilteredDuration = (duration) => {
    switch (duration) {
      case '0,600':
        return '10'
      case '601,1200':
        return '20'
      case '1201,1800':
        return '30'
      case '1801,18000':
        return '40'
      default :
        return null
    }
  }
  const getMediaType = (type) => {
    switch (type) {
      case 1:
        return 'Video'
      case 2:
        return 'Audio'
      default :
        return null
    }
  }

  const getLanguageTag=(value)=>{
    if(value&&value.includes('-')){
      const poData = getTranslatedFile().classLanguage
      const checkForkey = (value in poData)
      if(!checkForkey){
        return value.split('-')[0]
      }
      return value
    }
    else
      return value
  }
  const checkFilterSortBy = (input)=>{
    try {
      switch (input) {
        case "Newest":
        return Translate({ id: "filter.Newest" })
        case "Oldest":
          return Translate({ id: "filter.Oldest" })
        default:
          return input;
      }
    } catch (error) {
      return input;
    }
  }

  const mediaTypeConst = 'Media Type'
  return (
    <div
      className="customFilter"
      role="presentation"
    // onClick={props.openHandler}
    // onKeyDown={handleDrawerOpen()}
    >
      {
        props.initialFilterMetaData &&
        <div style={{height:'96%',background:"#fff"}}>
          <Grid container>
            <Grid item lg={8} md={8} sm={6} xs={6}>
              <Typography variant="h2" className="m-t-15" style={{ fontSize: "28px" }}>
                {Translate({ id: "ClassListViews.Filter" })}
              </Typography>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={6}>
              <div className="text-right">
                <IconButton
                  onClick={props.closeHandler}
                  className=""
                  style={{
                    paddingBottom: "0px",
                    background: "none",
                    marginTop: "2px",
                    paddingRight: "16px"
                  }}>
                  <ClearIcon className="text-black" style={{ fontSize: "2rem" }} />
                </IconButton>
              </div>
            </Grid>
          </Grid>
            <div className="filterOverflow">
              {!props.showFLLOnDemand && props.categoryName !== viewAllCategory.id && props.initialFilterMetaData.classSubCategories && props.initialFilterMetaData.classSubCategories.length > 0 && <List style={{ paddingBottom: 0, marginBottom: "-6px" }}>
              {/* <Accordion key={'Sub Category'} className="myAccordion"
              expanded={expanded === 'panel1'} onChange={handleChange('panel1')}
              >
                <AccordionSummary
                  expandIcon={<AddIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="text-gray filter-text v-center">{Translate({ id: "filter.SubCategory" })}</Typography>
                  <span style={{ flex: "auto", textAlign: "right" }}>
                    {filteredSubCategory === "" ? null : <Chip label={Translate({ id: `subcategories.${filteredSubCategory}`})} className="bg-green text-white chipUl m-l-16" style={{ height: "27px" }}></Chip>}
                  </span>
                </AccordionSummary>
                <AccordionDetails className="p-l-0 p-r-0" style={{ padding: "0px 0px 4px" }}>
                  <Typography component="div">
                    <Paper component="div" className="chipUl">
                      {props.initialFilterMetaData.classSubCategories.map((key, index) => (
                        <li key={index}>
                          <Chip label={Translate({ id: `subcategories.${key}`})} className={`makeTextClickable m-b-10 m-b-xs-10 ${key === filteredSubCategory ? "bg-green text-white" : ""}`}
                            onClick={() => {
                              fetchData('Sub Category', key)
                            }}
                          >
                          </Chip>
                        </li>
                      ))}
                    </Paper>
                  </Typography>
                </AccordionDetails>
              </Accordion> */}
            </List>}
            {props.initialFilterMetaData.labels && props.initialFilterMetaData.labels.length > 0 && <List style={{ paddingBottom: "0px" }}>
              <Accordion component="div" key={'Keywords'} className="myAccordion"
              expanded={expanded === 'panel2'} onChange={handleChange('panel2')}
              >
                <AccordionSummary
                  expandIcon={<AddIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="text-gray filter-text v-center">{Translate({ id: "filter.Keywords" })}</Typography>
                  <span style={{ flex: "auto", textAlign: "right" }}> 
                    {filteredKeywords == "" ? null : <Chip label={Translate({ id: `keywords.${filteredKeywords}`})} className="bg-green text-white chipUl m-l-16" style={{ height: "27px" }}></Chip>}
                  </span>
                </AccordionSummary>
                <AccordionDetails className="p-l-0 p-r-0" style={{ padding: "0px 0px 4px" }}>
                  <Typography component="div">
                    <Paper component="ul" className="chipUl">
                      {props.initialFilterMetaData.labels.map((key, index) => (
                        <li key={index}>
                          <Chip label={Translate({ id: `keywords.${key}`})} className={`makeTextClickable m-b-10 m-b-xs-10 ${key == filteredKeywords ? "bg-green text-white" : ""}`}
                            onClick={() => {
                              fetchData('Keywords', key)
                            }}
                          >
                          </Chip>
                        </li>
                      ))}
                    </Paper>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </List>}
            <List style={{ marginTop: "-4px", paddingBottom: "0px" }}>
              <Accordion key={'Duration'} className="myAccordion"
              expanded={expanded === 'panel3'} onChange={handleChange('panel3')}
              >
                <AccordionSummary
                  expandIcon={<AddIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="text-gray filter-text v-center">{Translate({ id: "filter.Duration" })}</Typography>
                  <span style={{ flex: "auto", textAlign: "right" }}>
                    {filteredDuration === "" ? null : <Chip label={`${getFilteredDuration(filteredDuration)} min`} className="bg-green text-white chipUl m-l-16" style={{ height: "27px" }}></Chip>}
                  </span>
                </AccordionSummary>
                <AccordionDetails className="p-l-0 p-r-0" style={{ padding: "0px 0px 4px" }}>
                  <Typography component="div">
                    <Paper component="div" className="chipUl">
                      {getDurationInMinsWithLabel(translatedFile).map((key, index) => (
                        <li key={index}>
                          <Chip label={key.label} className={`makeTextClickable m-b-10 m-b-xs-10 ${key.value === filteredDuration ? "bg-green text-white" : ""}`}
                            onClick={() => {
                              fetchData('duration', key.value)
                            }}
                          >
                          </Chip>
                        </li>
                      ))}
                    </Paper>
                  </Typography>
                </AccordionDetails>
              </Accordion >
            </List >
            {metadataEntitlement.skills && <List style={{ marginTop: "-5px", paddingBottom: "0px" }}>
              <Accordion key={'Skill Level'} className="myAccordion"
              expanded={expanded === 'panel4'} onChange={handleChange('panel4')}
              >
                <AccordionSummary
                  expandIcon={<AddIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="text-gray filter-text v-center">{Translate({ id: "filter.SkillLevel" })}
                  </Typography>
                  {(levelText !== "") &&
                    <Typography variant="caption" className="v-center" style={{marginLeft: "8px"}}>
                      {Translate({ id: `filter.${levelText}` })}</Typography>
                  }
                  <span style={{ flex: "auto", textAlign: "right" }}>
                    {filteredSkill.length>0 &&  <Chip label={`${skillValue[0]} - ${skillValue[1]}`} className="bg-green text-white chipUl m-l-16" style={{ height: "27px" }}></Chip>}
                  </span>
                </AccordionSummary>
                <AccordionDetails className="p-l-0 p-r-0" style={{ padding: "0px 0px 4px", marginLeft: 12, marginRight: "26px" }}>
                  <AirbnbSlider
                    // onChangeCommitted={handleSkillValueChange}
                    onChange={handleSkillValueChange}
                    value={skillValue}
                    max={10}
                    min={1}
                    step={1}
                    marks
                    valueLabelDisplay="auto"
                  />
                </AccordionDetails>
              </Accordion>
            </List>}
              {metadataEntitlement?.focusArea &&
              props.initialFilterMetaData.focusArea && props.initialFilterMetaData.focusArea.length > 0 &&
              <List style={{ marginTop: "-5px", paddingBottom: "0px" }}>
                <Accordion key={'Focus Area'} className="myAccordion"
                expanded={expanded === 'panel5'} onChange={handleChange('panel5')}
                >
                  <AccordionSummary
                    expandIcon={<AddIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className="text-gray filter-text v-center">{Translate({ id: "filter.FocusArea" })}</Typography>
                    <span style={{ flex: "auto", textAlign: "right" }}>
                      {filteredFocusArea.length === 0 ? null :filteredFocusArea[0].filterTag!==""&& <Chip label={Translate({ id: `focusArea.${filteredFocusArea[0].filterTag}`})} className="bg-green text-white chipUl m-l-16" style={{ height: "27px" }}></Chip>}
                    </span>
                  </AccordionSummary>
                  <AccordionDetails className="p-l-0 p-r-0" style={{ padding: "0px 0px 4px" }}>
                    <Typography component="div">
                      <Paper component="ul" className="chipUl">
                        {props.initialFilterMetaData.focusArea.map((key, index) => (
                          <li key={index}>
                            <Chip label={Translate({ id: `focusArea.${key.tag}`})} className={`makeTextClickable m-b-10 m-b-xs-10 ${filteredFocusArea.length>0?key.name === filteredFocusArea[0].filterValue ? "bg-green text-white" : "":""}`}
                              onClick={() => {
                                fetchData('Focus Area', key.name,key.tag)
                              }}
                            ></Chip>
                          </li>
                        ))}
                      </Paper>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </List >}
              {metadataEntitlement?.equipment &&
              props.initialFilterMetaData.equipments && props.initialFilterMetaData.equipments.length > 0 && <List style={{ marginTop: "-5px", paddingBottom: "0px" }}>
                <Accordion key={'Equipment'} className="myAccordion"
                expanded={expanded === 'panel6'} onChange={handleChange('panel6')}
                >
                  <AccordionSummary
                    expandIcon={<AddIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className="text-gray filter-text v-center">{Translate({ id: "filter.Equipment" })}</Typography>
                    <span style={{ flex: "auto", textAlign: "right" }}>
                      {filteredEquipments.length === 0? null :filteredEquipments[0].filterTag!==""&& <Chip label={Translate({ id: `equipment.${filteredEquipments[0].filterTag}`})} className="bg-green text-white chipUl m-l-16" style={{ height: "27px" }}></Chip>}
                    </span>
                  </AccordionSummary>
                  <AccordionDetails className="p-l-0 p-r-0" style={{ padding: "0px 0px 4px" }}>
                    <Typography component="div">
                      <Paper component="ul" className="chipUl">
                        {props.initialFilterMetaData.equipments.map((key, index) => (
                          <li key={index}>
                            <Chip label={Translate({ id: `equipment.${key.typeTag}`})} className={`makeTextClickable m-b-10 m-b-xs-10 ${filteredEquipments.length>0?key.name===filteredEquipments[0].filterValue ? "bg-green text-white" : "":""}`} onClick={() => {
                              fetchData('equipment', key.name,key.typeTag)
                            }
                            }>
                            </Chip>
                          </li>
                        ))}
                      </Paper>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </List >}
            {metadataEntitlement.intensity && <List style={{ marginTop: "-5px", paddingBottom: "0px" }}>
              <Accordion key={'Intensity'} className="myAccordion"
              expanded={expanded === 'panel7'} onChange={handleChange('panel7')}
              >
                <AccordionSummary
                  expandIcon={<AddIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="text-gray filter-text  v-center">{Translate({ id: "filter.Intensity" })}</Typography>
                  {(intensityText !== "") &&
                    <Typography variant="caption" className="v-center" style={{ marginLeft: "8px"}}>
                      {Translate({ id: `filter.${intensityText}` })}</Typography>
                  }
                  <span style={{ flex: "auto", textAlign: "right" }}>
                    {filteredIntensity.length>0 &&  <Chip label={`${intensityValue[0]} - ${intensityValue[1]}`} className="bg-green text-white chipUl m-l-16" style={{ height: "27px" }}></Chip>}
                  </span>
                </AccordionSummary>
                <AccordionDetails className="p-l-0 p-r-0" style={{ padding: "0px 0px 4px", marginLeft: 12, marginRight: "26px" }}>
                  <AirbnbSlider
                    onChange={handleIntensityValue}
                    value={intensityValue}
                    max={10}
                    min={1}
                    step={1}
                    marks
                    valueLabelDisplay="auto"
                  />
                </AccordionDetails>
              </Accordion>
            </List>}
            {
              props.initialFilterMetaData.classLanguage && props.initialFilterMetaData.classLanguage.length > 0 && <List style={{ marginTop: "-5px", paddingBottom: "0px" }}>
                <Accordion key={'Class Language'} className="myAccordion"
                expanded={expanded === 'panel8'} onChange={handleChange('panel8')}
                >
                  <AccordionSummary
                    expandIcon={<AddIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className="text-gray filter-text v-center">{Translate({ id: "filter.ClassLanguage" })}</Typography>
                    <span style={{ flex: "auto", textAlign: "right" }}>
                      {filteredLanguage.length === 0? null :filteredLanguage[0].filterTag!==""&& <Chip label={Translate({ id: `classLanguage.${filteredLanguage[0].filterTag}`})} className="bg-green text-white chipUl m-l-16" style={{ height: "27px" }}></Chip>}
                    </span>                                  
                  </AccordionSummary>
                  <AccordionDetails className="p-l-0 p-r-0" style={{ padding: "0px 0px 4px" }}>
                    <Typography component="div">
                      <Paper component="ul" className="chipUl">
                        {props.initialFilterMetaData.classLanguage.map((key, index) => (
                          <li key={index}>
                            <Chip label={Translate({ id: `classLanguage.${getLanguageTag(key.text)}`})} className={`m-b-10 m-b-xs-10 makeTextClickable ${filteredLanguage.length>0?key.value === filteredLanguage[0].filterValue  ? "bg-green text-white" : "":""}`} onClick={() => {
                              fetchData('Class Language', key.value,getLanguageTag(key.text))
                            }
                            }>
                            </Chip>
                          </li>
                        ))}
                      </Paper>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </List >}
            <List style={{ marginTop: "-5px", paddingBottom: "0px" }}>
              <Accordion key={'Sort'} className="myAccordion"
              expanded={expanded === 'panel9'} onChange={handleChange('panel9')}
              >
                <AccordionSummary
                  expandIcon={<AddIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="text-gray filter-text v-center">{Translate({ id: "filter.Sort" })}</Typography>
                <span style={{ flex: "auto", textAlign: "right" }}>
                      <Chip label={filterSortBy === "" ?Translate({ id: "filter.Newest" }):  checkFilterSortBy(filterSortBy)} className="bg-green text-white chipUl m-l-16" style={{ height: "27px" }}></Chip>
                    </span>
                </AccordionSummary>
                <AccordionDetails className="p-l-0 p-r-0" style={{ padding: "0px 0px 4px" }}>
                  <Typography component="div">
                    <Paper component="ul" className="chipUl">
                      {SortValues.map((key) => (
                        <li key={Math.random()}>
                          <Chip label={key.label} className={`m-b-10 m-b-xs-10 makeTextClickable ${(filterSortBy === ""&&key.value ==="Newest")||key.value === filterSortBy ? "bg-green text-white" : ""}`} onClick={() => {
                            fetchData('Sort', key.value)
                          }
                          }>
                          </Chip>
                        </li>
                      ))}
                    </Paper>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </List>
            {
              props.initialFilterMetaData.mediaType && props.initialFilterMetaData.mediaType.length > 0 && <List style={{ marginTop: "-5px", paddingBottom: "0px" }}>
                <Accordion key={mediaTypeConst} className="myAccordion"
                expanded={expanded === 'panel11'} onChange={handleChange('panel11')}
                >
                  <AccordionSummary
                    expandIcon={<AddIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className="text-gray filter-text v-center">{Translate({ id: "filter.MediaType" })}</Typography>
                    <span style={{ flex: "auto", textAlign: "right" }}>
                        {filteredMediaType.length === 0 ? <Chip label={Translate({ id: `liveStream.All`})} className="bg-green text-white chipUl m-l-16" style={{ height: "27px" }}></Chip> :filteredMediaType!==""&& <Chip label={Translate({ id: `filter.${getMediaType(filteredMediaType)}`})} className="bg-green text-white chipUl m-l-16" style={{ height: "27px" }}></Chip>}
                    </span>                                  
                  </AccordionSummary>
                  <AccordionDetails className="p-l-0 p-r-0" style={{ padding: "0px 0px 4px" }}>
                    <Typography component="div">
                      <Paper component="ul" className="chipUl">
                          <li>
                            <Chip label={Translate({ id: `liveStream.All`})} className={`m-b-10 m-b-xs-10 makeTextClickable ${filteredMediaType.length === 0 ? "bg-green text-white" : ""}`} onClick={() => {
                              fetchData(mediaTypeConst,'')
                            }
                            }>
                            </Chip>
                          </li>
                          <li>
                            <Chip label={Translate({ id: `filter.Video`})} className={`m-b-10 m-b-xs-10 makeTextClickable ${filteredMediaType? 1 === filteredMediaType  ? "bg-green text-white" : "":""}`} onClick={() => {
                              fetchData(mediaTypeConst, 1)
                            }
                            }>
                            </Chip>
                          </li>
                          <li>
                            <Chip label={Translate({ id: `filter.Audio`})} className={`m-b-10 m-b-xs-10 makeTextClickable ${filteredMediaType? 2 === filteredMediaType  ? "bg-green text-white" : "":""}`} onClick={() => {
                              fetchData(mediaTypeConst, 2)
                            }
                            }>
                            </Chip>
                          </li>
                      </Paper>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </List >}
            <List >
              <ListItem button style={{ borderBottom: "solid 1px #d1d1d6", padding: "0", background: "none" }}>
                <TextField
                  className="searchFilter" style={{ minHeight: "24px", width: "99%" }}
                  id="input-with-icon-textfield"
                  // placeholder="Search Classes"
                  placeholder={translatedFile?.ClassListViews?.SearchClasses}
                  onKeyPress={handleKeyPress}
                  value={searchText}
                  onKeyDown={searchEnterPressed}
                  onChange={e => {
                     setReducerData(filterDrawerDispatch,{ searchText:(e.target.value)})
                    if (e.target.value.length > 0) {
                      setReducerData(filterDrawerDispatch,{ clearSearch : 'block',})

                    }
                    else {
                      setReducerData(filterDrawerDispatch,{clearSearch : 'none',})
                      if(props.showFLLOnDemand)
                          setOnDemandFilterSearchText('')
                      else
                          setFilterSearchText('')
                    }
                  }
                  }
                  inputProps={{ type:isAndroid?"search":"text", enterKeyHint:isIOS?"search":"" }}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className={"makeTextClickable"} fontSize="medium" onClick={() => { if (searchText!==undefined&&searchText.length > 0) searchData() }} />
                        {/* <ClearSearch /> */}
                        <span
                        onClick={() => {
                          setReducerData(filterDrawerDispatch,{ searchtext: '', clearSearch : 'none',})
                          if(props.showFLLOnDemand)
                            setOnDemandFilterSearchText('')
                          else
                            setFilterSearchText('')
                        }
                        }
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          right: "5px",
                          display: clearSearch
                        }}
                        >
                          <ClearSearch />
                        </span>
                      </InputAdornment>
                    ),
                  }}
                />
              </ListItem>
            </List>
          </div>
          <Grid container className={isEmbeddedMode() ? "footer-fixed m-2" : "footer-fixed"}>
            <Grid item lg={12} md={12} sm={12} xs={12} className="text-center">
              <Typography variant="h2" className="m-t-30">
                {props.classesCount}
              </Typography>
              <Button
                type="submit"
                variant="contained"
                disabled={props.classesCount < 1}
                color="secondary"
                className="button m-t-md-15 btn-default"
                style={{ width: "100%", height: 48, borderRadius: 35, margin: '5px 0' }}
                onClick={viewClasses}>
                <Typography variant="button" style={{ color: "secondary" }}>
                  {Translate({ id: "ClassListViews.ViewClasses" })}
                </Typography>
              </Button>
              <a className="dynamiclinks linkFontSize makeTextClickable" onClick={() => {
                setReducerData(filterDrawerDispatch,{searchText:''}) 
                if(props.showFLLOnDemand)
                  resetOnDemandFilters()
                else
                  resetFilters()
                props.fetchDataByFilters([], false, true)
              }}>
                {Translate({ id: "ClassListViews.ResetFilters" })}
              </a>
            </Grid>
          </Grid>
        </div >
      }
    </div >
  )
};

export default FilterDrawer;

