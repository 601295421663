import Routes from "components/Router/Routes";
import { Translate } from "./Translate";

export const CLASS_LOG_TIMEOUT = 59; // RR Only after this time Leave PopUp will be shown on Player.

export const workoutResultType = {
    UnTracked: 0,
    DidNotWorkout: 1,
    Workout: 2,
    WorkoutResult: 3,
    Class: 4,
    Challenge: 5,
    PremiumPlan: 6,
    VirtualClass: 7,
    ClubConnectVideo: 8,
    LiveStream: 9,
    Channel: 10/// add channel type
}

export const SubscriptionProductType = {
    Annual: "Annually",
    Quarterly: "Quarterly",
    Monthly: "Monthly",
    Trial: "trial", // don't change the value, it will impact the trial period.(src\store\selectors\subscription.js)
    Daily: "Daily"

}

export const IntensityValueText = {
            VerySlight:"VerySlight",
            Slight:"Slight",
            Moderate:"Moderate",
            SomewhatHard:"SomewhatHard",
            Hard:"Hard",
            VeryHard: "VeryHard",
            VeryVeryHard:"VeryVeryHard",
            Maximal:"Maximal"
}

export const StripeSourceType = {
    Card: "card"
}

export const ConsentType = {
    TNC: "tnc",
    Marketing: "mktg"
}

export const ConsentAction = {
    Rejected: 1,
    Accepted: 2
}
export const SubscriberStatusConstants = {
    INACTIVE: 0,
    ACTIVE: 1,
    UNKNOWN: 2
}
export const isMobileOrTab = () =>{
    try {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    catch(e){
        return false
    }
}
export const isIPAD = () => {
    try {
        let isiPad = navigator.userAgent.match(/iPad/i) != null;
        return isiPad
    } catch (e) {
        return false;
    }
};
export const LiveStreamStatusConstants = {
    UNAVAILABLE: "unavailable",
    STREAMING: "streaming",
    AWAITING: "awaiting"
}

export const SpinnerConstants = {
    SIZE: 70,
    THICKNESS: 5
}

const getCardCount = (device,cardCount) => {
    switch (device) {
        case 'mobile':
            return cardCount
        case 'desktop':
            return cardCount    
        default:
            break;
    }
}

const checkMediaQuery = (result,device,cardCount) => {
    if (typeof result !== 'undefined' && result.addEventListener) {
    
    if (result.matches) {
       return getCardCount(device,cardCount)
      }

      
      result.addEventListener("change", (event) => {
      
        if (event.matches) {
           return getCardCount(device,cardCount) 
        }
      
      });
    }
}

export const lazyLoadingPageDynamic =() =>{
    window.matchMedia = window.matchMedia || function() {
        return {
            matches: false,
            addListener: jest.fn(),
            removeListener: jest.fn()
        };
    };
    const isMobileORIsTab = window.matchMedia("(max-width: 1359px)");
    const isDesktopORIsProjector= window.matchMedia("(min-width:1360px )");

    const mobile = checkMediaQuery(isMobileORIsTab,"mobile",18)
    const desktop = checkMediaQuery(isDesktopORIsProjector,'desktop',20)

    if(isMobileORIsTab.matches) return mobile
    if(isDesktopORIsProjector.matches) return desktop

    
};

export const LAZY_LOADING_PAGE_SIZE = lazyLoadingPageDynamic();

export const SSO_TOKEN_EXPIRE_BUFFER_SEC = 10;

export const PROFILE = {
    MYSUBSCRIPTIONS: "subscription",
    FAVORITES : "favourites",
    RECENT : "recent",
    REVIEWS: 'reviews',
    ACCOUNT : "account",
    UPDATE : "update",
    PLAYLIST : "playlist",
}
export const ClassType = {
  CLASSES: "CLASSES",
  LIVE_SIMULATED_SVOD: "LIVE_SIMULATED_SVOD",
  COLLECTIONS: "COLLECTIONS",
};
export const PlayList = {
    msg : {
        get : "fetch successfully",
        created : "Playlist created.",
        updated : "Playlist updated.",
        orderUpdated : "Playlist order updated.",
        reordered : "Reorder successfully",
        deleted : "Playlist deleted.",
        classDeleted : "Class deleted.",
        error : "An error occurred, please try again later."
    },
    notification:{
        severity : {
            success : "success",
            error : "error",
        },
        showTime:3000
    }
}
export const apiStatusCode = {
    success : [200,201,204],
    error:[400,401,500]
};
export const MINIMUM_GLOBAL_SEARCH = 2;
export const EVENT_SOURCE = {
  WEXER: 1,
  EXTERNAL: 2,
};


export const ClassMediaType = {
    Audio: 2,
    Video: 1,
}

export const viewAllCategory = {
    id: "ViewAll",
    name: Translate({ id: `categoryName.ViewAll` }),
    description: Translate({ id: `categoryDescription.ViewAll` }),
}

export const contentListConstants = {
    channel:{
        title : "homePage.ChannelsTitle",
        route : "/channels",
        id:"channels-id"
    },
    collection:{
        title : "homePage.CollectionsTitle",
        route : Routes.collections,
        id:"collections-id"
    },
    category:{
        title: "ClassListViews.Search",
        route : Routes.searchRoute,
        id:"category-id"
    }
}

export const platformSource = {
    "inapp": "inapp",
    "stripe": "stripe",
    "others": "others"
}

export const TenantLockedContentBehaviour = {
  do_nothing: "do_nothing",
  show_popup: "show_popup",
  show_subscription_options: "show_subscription_options",
};

export const subscriptionTypes =  {
    ALLINONE : "allinone",
    BUNDLE : "bundle",
    RENTEL : "rental",
    PAYPERVIEW : "payperview",
}
export const IS_PLAYLIST_PLAYER = "PlaylistPlayer"; 

export const homePageItemCount = {
    pageNo: 1,
    items: 5
}

export const subCategoryFilterType = "sub category"

export const SkillLevelText = {
    0: 'SkillLevel_1',
    1: 'SkillLevel_1',
    2: 'SkillLevel_2',
    3: 'SkillLevel_3',
    4: 'SkillLevel_4',
    5: 'SkillLevel_5',
    6: 'SkillLevel_6',
    7: 'SkillLevel_7',
    8: 'SkillLevel_8',
    9: 'SkillLevel_9',
    10: 'SkillLevel_10'
}

export const IntensityLevelText = {
    0: 'IntensityLevel_1',
    1: 'IntensityLevel_1',
    2: 'IntensityLevel_2',
    3: 'IntensityLevel_3',
    4: 'IntensityLevel_4',
    5: 'IntensityLevel_5',
    6: 'IntensityLevel_6',
    7: 'IntensityLevel_7',
    8: 'IntensityLevel_8',
    9: 'IntensityLevel_9',
    10: 'IntensityLevel_10'
}

